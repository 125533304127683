import { Stack, Typography, useTheme } from '@mui/material'
import * as React from 'react'
import { FixedSizeList } from 'react-window'
import useAsync from '../../hooks/useAsync'
import i18n, { keys } from '../../i18n'
import { LoadingControlBackdrop } from '../Loading/LoadingControlBackdrop'
import { useDrillDownMenuContext } from './DrillDownMenuContext'
import { DrillDownRow } from './DrillDownRow'
import { Item, Level, LevelInfo } from './types'

interface Props {
  level: Level
  levelIndex: number
  levels: Level[]
  setCurrentLevelInfo: (levelInfo: LevelInfo) => void
  currentLevelInfo: LevelInfo
  height: number
  width: number
}

interface RowData {
  items: Item[]
  levelIndex: number
  setCurrentLevelInfo: (levelInfo: LevelInfo) => void
  currentLevelInfo: LevelInfo
  level: Level
  levels: Level[]
  width: number
}

const Row = ({
  index,
  style,
  data: {
    items,
    levelIndex,
    setCurrentLevelInfo,
    currentLevelInfo,
    level,
    levels,
    width,
  },
}: {
  index: number
  style: React.CSSProperties
  data: RowData
}) => (
  <DrillDownRow
    key={items[index].id}
    level={level}
    levels={levels}
    levelIndex={levelIndex}
    setCurrentLevelInfo={setCurrentLevelInfo}
    currentLevelInfo={currentLevelInfo}
    index={index}
    data={items}
    style={style}
    width={width}
  />
)

export const DrillDownList = ({
  level,
  levelIndex,
  levels,
  setCurrentLevelInfo,
  currentLevelInfo,
  height,
  width,
}: Props) => {
  const theme = useTheme()
  const { setLoadedItems, listRef } = useDrillDownMenuContext()
  const [fetchItems] = useAsync(async () => {
    const parentItem = currentLevelInfo.parentHistory[levelIndex]
    return (await level?.getItems?.(parentItem)) ?? []
  }, [currentLevelInfo.parentHistory[levelIndex], level.getItems])

  React.useEffect(() => {
    level?.onItemsLoading?.()
  }, [fetchItems.status, level])

  React.useEffect(() => {
    setLoadedItems(fetchItems.result)
    level.onItemsLoaded?.(fetchItems.result ?? [], listRef)
  }, [fetchItems.result, level, listRef, setLoadedItems])

  const data = React.useMemo(
    () => ({
      items: fetchItems.result,
      levelIndex: levelIndex,
      setCurrentLevelInfo: setCurrentLevelInfo,
      currentLevelInfo: currentLevelInfo,
      level: level,
      levels: levels,
    }),
    [
      currentLevelInfo,
      fetchItems.result,
      level,
      levelIndex,
      levels,
      setCurrentLevelInfo,
    ]
  )

  if (fetchItems.status !== 'pending' && (data?.items?.length ?? 0) === 0) {
    return !level?.EmptyListComponent ? (
      <Stack
        width={width}
        height={height}
        alignItems="center"
        justifyContent="center"
      >
        <Typography color={theme.palette.divider}>
          {i18n.t(keys.generic.noItems)}
        </Typography>
      </Stack>
    ) : (
      <level.EmptyListComponent />
    )
  }

  return (
    <LoadingControlBackdrop open={fetchItems.status === 'pending'}>
      <FixedSizeList
        ref={listRef}
        className={`${level.id}-level-list`}
        itemCount={data?.items?.length ?? 0}
        itemData={data}
        itemSize={60}
        height={height}
        width={width}
        style={{
          overflowX: 'hidden',
          scrollbarColor: '#fff transparent',
          scrollbarWidth: 'none',
        }}
      >
        {Row}
      </FixedSizeList>
    </LoadingControlBackdrop>
  )
}
