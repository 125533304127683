import * as React from 'react'

import {
  Box,
  FormControl,
  FormLabel,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material'

import { Row } from '../../admin/UI/Row'
import { selectMapLayerDefsById } from '../../data/selectListMapSourceDefs'
import i18n, { keys } from '../../i18n'
import { selectSelectedProduct } from '../../postgis/selectSelectedProduct'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { formatNum } from '../../util/formatNumber'
import { updateProductSettings } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'
import { selectSelectedGroupProduct } from '../store/selectors/selectSelectedGroupProduct'
import { ProductSettings } from '../store/types'

class DotSize extends React.PureComponent<AppDispatchProps & ReduxProps> {
  handleChangeDotSize = (dotSize?: ProductSettings['dotSize']) => {
    const { productId, dispatch } = this.props

    dispatch(
      updateProductSettings({
        productId,
        settings: { dotSize },
      })
    )
  }

  render() {
    const { settings, products, productId, selectedGroupProduct } = this.props

    if (
      !settings ||
      !productId ||
      !products ||
      !products[productId] ||
      products[productId].mapSourceDef.type !== 'point' ||
      selectedGroupProduct?.endsWith('block-by-block')
    ) {
      return null
    }

    const { dotSize = 'medium' } = settings

    return (
      <FormControl fullWidth sx={{ mt: 2 }}>
        <FormLabel id="dot-size-label" sx={{ mb: 2, fontSize: 12 }}>
          {i18n.t(keys.dotSize)}
        </FormLabel>
        <Row style={{ justifyContent: 'center', width: '100%' }}>
          <ToggleButtonGroup
            value={dotSize}
            exclusive
            color="primary"
            onChange={(_ev, dotsize) => this.handleChangeDotSize(dotsize)}
            aria-label="dot size"
            fullWidth
          >
            <ToggleButton value="small" aria-label="small">
              <Tooltip title={i18n.t(keys.small)}>
                <Box
                  component="span"
                  sx={{
                    bgcolor: 'primary.main',
                    height: 10,
                    width: 10,
                    borderRadius: 10,
                  }}
                />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="medium" aria-label="medium">
              <Tooltip title={i18n.t(keys.medium)}>
                <Box
                  component="span"
                  sx={{
                    bgcolor: 'primary.main',
                    height: 15,
                    width: 15,
                    borderRadius: 15,
                  }}
                />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="large" aria-label="large">
              <Tooltip title={i18n.t(keys.large)}>
                <Box
                  component="span"
                  sx={{
                    bgcolor: 'primary.main',
                    height: 20,
                    width: 20,
                    borderRadius: 20,
                  }}
                />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="extraLarge" aria-label="extra large">
              <Tooltip title={i18n.t(keys.extraLarge)}>
                <Box
                  component="span"
                  sx={{
                    bgcolor: 'primary.main',
                    height: 25,
                    width: 25,
                    borderRadius: 25,
                  }}
                />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Row>
      </FormControl>
    )
  }

  formatValue(value?: number, zeroIsNone = false) {
    if (zeroIsNone && value !== undefined) {
      return `${formatNum(100 * value, true)}%`
    }

    return value !== undefined
      ? `${formatNum(100 * value, true)}%`
      : i18n.t(keys.generic.none)
  }
}

const mapState = (state: RootStore) => {
  const product = selectSelectedProduct(state)

  const productId = product?.id
  const productName = product?.name

  return {
    productId,
    productName,
    products: selectMapLayerDefsById(state),
    settings: selectActiveProductSettings(state),
    selectedGroupProduct: selectSelectedGroupProduct(state),
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(DotSize)
