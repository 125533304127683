import { Stack, Typography, styled } from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'
import { SelectProject } from '../SelectProject'

interface Props {
  newProjectId: string | undefined
  onNewProjectIdChange: (projectId?: string) => void
}

const OptionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 200,
  lineHeight: '19.2px',
  letterSpacing: '-0.035em',
  textAlign: 'left',
}))

const OptionStack = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  flexDirection: 'column',
}))

export const MoveNotesForm = ({
  newProjectId,
  onNewProjectIdChange,
}: Props) => {
  return (
    <Stack direction="column" gap={4} width={432}>
      <OptionStack>
        <OptionTitle>{i18n.t(keys.moveNotesModalDescription)}</OptionTitle>
        <SelectProject
          selectedProjectId={newProjectId}
          type="notes"
          onProjectChange={(projectId) => {
            onNewProjectIdChange(projectId)
          }}
        />
      </OptionStack>
    </Stack>
  )
}
