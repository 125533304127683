import * as React from 'react'
import { Parcel, RateMap, RateMapCustomZone } from '../../../graphql/types'
import Exporter from '../../../hooks/useExportable/formats/formatters'
import {
  DownloadFunction,
  useExportable,
} from '../../../hooks/useExportable/useExportable'
import { useRateMapContext } from '../editor/RateMapContext'

import {
  feature,
  Feature,
  featureCollection,
  MultiPolygon,
  Polygon,
} from '@turf/helpers'
import { area as geometryArea } from '@turf/turf'
import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { selectPreferredLanguage } from '../../../data/selectPreferredLanguage'
import { useRedux } from '../../../hooks/useRedux'
import i18n, { keys } from '../../../i18n'
import * as drawHelpers from '../../../map/draw/util/drawHelpers'
import { selectRateMapCustomZoneRatesByZoneId } from '../selectors/selectRateMapCustomZoneRatesByZoneId'
import area from './../../../util/units/area'
import { useGenerateAmendmentZones } from './useGenerateAmendmentZones'
import { useRateMapVigorZoneFeatures } from './useRateMapVigorZoneFeatures'

const eviPropertyName = 'EVI'
const areaPropertyName = 'Area_m2'

export const useRateMapExportable = (): [DownloadFunction] => {
  const [state] = useRedux()

  const {
    getCustomZoneAdjustedAdjustedVigorZonesByParcelIdAndStopIndex,
    getRateMapZoneFeaturesByParcelIdAndStopIndex,
  } = useRateMapVigorZoneFeatures()

  const rateMapState = useRateMapContext()
  const customZonesRates = selectRateMapCustomZoneRatesByZoneId(rateMapState)
  const { rateMap, amendmentTypes, unitTypes } = rateMapState
  const [rateMapAmendments] = useGenerateAmendmentZones()
  const preferredLanguage = selectPreferredLanguage(state)
  const preferredUnitSystem = state.preferences.preferredUnitSystem

  const customZones = React.useMemo(
    () => rateMap?.RateMapCustomZones.filter((zone) => !zone.deletedAt) ?? [],
    [rateMap?.RateMapCustomZones]
  )

  const formatCsv = React.useCallback(
    (
      rateMap: RateMap,
      parcelSelection: Parcel[],
      rateMapZoneFeaturesByParcelIdAndStopIndex: Record<
        string,
        Record<string, MapboxGeoJSONFeature[]>
      >,
      rateMapCustomZones: RateMapCustomZone[]
    ) => {
      if (!rateMapAmendments) {
        return []
      }

      const data: any[] = []

      const rateUnit = unitTypes[rateMap.unitTypeId].name[
        preferredUnitSystem
      ]?.[preferredLanguage]
        ?.replaceAll('/', '_')
        ?.toUpperCase()

      const amountUnit = rateUnit?.split('_')?.[0]

      const total: any = {
        [i18n.t(keys.stats.block)]: 'Total',
        EVI_Class: '-',
        Area_Ac: 0,
        Area_Ha: 0,
        [i18n.t(keys.rateMapAmendmentTypeTitle)]:
          amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
            preferredLanguage
          ] ??
          amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
          '',

        [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]: '-',
        [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: 0,
      }

      for (const parcel of parcelSelection) {
        for (const amendment of rateMapAmendments) {
          if (amendment.isCustomZone || amendment.stopIndex === undefined) {
            continue
          }
          const zoneInfo =
            rateMapZoneFeaturesByParcelIdAndStopIndex[parcel.id]?.[
              amendment.stopIndex
            ]
          if (!zoneInfo || zoneInfo.length === 0) {
            continue
          }

          const zoneArea = zoneInfo?.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[areaPropertyName] ?? 0),
            0
          )

          const convertedAreaAcres = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.acre,
            'acre'
          ).value

          const convertedAreaHectares = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.hectare,
            'hectare'
          ).value

          const amount =
            (amendment.rateOfApplication ?? 0) *
            (preferredUnitSystem === 'metric'
              ? convertedAreaHectares
              : convertedAreaAcres)

          const row: any = {
            [i18n.t(keys.stats.block)]: parcel.name,
            EVI_Class: amendment.value,
            Area_Ac: convertedAreaAcres,
            Area_Ha: convertedAreaHectares,
            [i18n.t(keys.rateMapAmendmentTypeTitle)]:
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
                preferredLanguage
              ] ??
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
              '',
            [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]:
              amendment.rateOfApplication,
            [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: amount,
          }
          data.push(row)

          total.Area_Ac += row.Area_Ac
          total.Area_Ha += row.Area_Ha
          total[`${i18n.t(keys.rateMapAmount)}_${amountUnit}`] +=
            row?.[`${i18n.t(keys.rateMapAmount)}_${amountUnit}`] ?? 0
        }
      }

      for (const customZone of rateMapCustomZones) {
        const zoneArea = customZone.geometry
          ? geometryArea(customZone.geometry)
          : 0
        const areaAc = area.convert(
          zoneArea,
          area.units.squareMeter,
          area.units.acre,
          'acre'
        ).value
        const areaHa = area.convert(
          zoneArea,
          area.units.squareMeter,
          area.units.hectare,
          'hectare'
        ).value
        const rate = customZonesRates?.[customZone?.id ?? '']?.rate ?? 0
        const amount =
          rate * (preferredUnitSystem === 'metric' ? areaHa : areaAc)
        const row: any = {
          [i18n.t(keys.stats.block)]: 'Custom Zone',
          EVI_Class: '-',
          Area_Ac: areaAc,
          Area_Ha: areaHa,
          [i18n.t(keys.rateMapAmendmentTypeTitle)]:
            amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
              preferredLanguage
            ] ??
            amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
            '',
          [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]: rate,
          [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: amount,
        }
        total.Area_Ac += row.Area_Ac
        total.Area_Ha += row.Area_Ha
        total[`${i18n.t(keys.rateMapAmount)}_${amountUnit}`] +=
          row?.[`${i18n.t(keys.rateMapAmount)}_${amountUnit}`] ?? 0

        data.push(row)
      }

      data.push(total)

      return data
    },
    [
      rateMapAmendments,
      unitTypes,
      preferredUnitSystem,
      preferredLanguage,
      amendmentTypes,
      customZonesRates,
    ]
  )

  const formatGeoJson = React.useCallback(
    (
      rateMap: RateMap,
      parcelSelection: Parcel[],
      rateMapZoneFeaturesByParcelIdAndStopIndex: Record<
        string,
        Record<string, MapboxGeoJSONFeature[]>
      >,
      rateMapCustomZones: RateMapCustomZone[]
    ) => {
      if (!rateMapZoneFeaturesByParcelIdAndStopIndex) {
        return featureCollection([])
      }

      const features: Feature<Polygon | MultiPolygon>[] = []

      const rateUnit = unitTypes[rateMap.unitTypeId].name[
        preferredUnitSystem
      ]?.[preferredLanguage]
        ?.replaceAll('/', '_')
        ?.toUpperCase()

      const amountUnit = rateUnit?.split('_')?.[0]

      for (const parcel of parcelSelection) {
        for (const amendment of rateMapAmendments) {
          if (amendment.isCustomZone || amendment.stopIndex === undefined) {
            continue
          }
          const zoneInfo = rateMapZoneFeaturesByParcelIdAndStopIndex[
            parcel.id
          ]?.[amendment.stopIndex] as Feature<Polygon | MultiPolygon>[]
          if (!zoneInfo || zoneInfo.length === 0) {
            continue
          }
          const eviTotal = zoneInfo.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[eviPropertyName] ?? 0),
            0
          )
          const EVImean = eviTotal / zoneInfo.length

          const zoneArea = zoneInfo?.reduce(
            (acc, zone) =>
              acc + Number(zone.properties?.[areaPropertyName] ?? 0),
            0
          )

          const convertedAreaAcres = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.acre,
            'acre'
          ).value

          const convertedAreaHectares = area.convert(
            zoneArea,
            area.units.squareMeter,
            area.units.hectare,
            'hectare'
          ).value

          const amount =
            (amendment.rateOfApplication ?? 0) *
            (preferredUnitSystem === 'metric'
              ? convertedAreaHectares
              : convertedAreaAcres)

          const multiPolygon = drawHelpers.union(...zoneInfo)

          if (multiPolygon) {
            features.push(
              feature(multiPolygon.geometry, {
                [i18n.t(keys.stats.block)]: parcel.name,
                EVImean,
                EVI_Class: amendment.value,
                Area_Ac: convertedAreaAcres,
                Area_Ha: convertedAreaHectares,
                [i18n.t(keys.rateMapAmendmentTypeTitle)]:
                  amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
                    preferredLanguage
                  ] ??
                  amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
                  '',
                [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]:
                  amendment.rateOfApplication,
                [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: amount,
              })
            )
          }
        }
      }
      for (const customZone of rateMapCustomZones) {
        if (!customZone.geometry) {
          continue
        }
        const zoneArea = customZone.geometry
          ? geometryArea(customZone.geometry)
          : 0
        const areaAc = area.convert(
          zoneArea,
          area.units.squareMeter,
          area.units.acre,
          'acre'
        ).value
        const areaHa = area.convert(
          zoneArea,
          area.units.squareMeter,
          area.units.hectare,
          'hectare'
        ).value
        const rate = customZonesRates?.[customZone?.id ?? '']?.rate ?? 0
        const amount =
          rate * (preferredUnitSystem === 'metric' ? areaHa : areaAc)
        features.push(
          feature(customZone.geometry, {
            [i18n.t(keys.stats.block)]: customZone.Parcel?.name ?? '',
            Area_Ac: areaAc,
            Area_Ha: areaHa,
            [i18n.t(keys.rateMapAmendmentTypeTitle)]:
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name?.[
                preferredLanguage
              ] ??
              amendmentTypes[rateMap?.amendmentTypeId ?? '']?.name.en ??
              '',
            [`${i18n.t(keys.rateMapRate)}_${rateUnit}`]: rate,
            [`${i18n.t(keys.rateMapAmount)}_${amountUnit}`]: amount,
          })
        )
      }
      return featureCollection(features)
    },
    [
      amendmentTypes,
      customZonesRates,
      preferredLanguage,
      preferredUnitSystem,
      rateMapAmendments,
      unitTypes,
    ]
  )

  const [download] = useExportable<RateMap>({
    data: rateMap ?? ({} as RateMap),
    formatters: [
      Exporter.exportCsv(formatCsv, 'csv'),
      Exporter.exportGeoJson(formatGeoJson, 'geojson'),
      Exporter.exportShapefile(formatGeoJson, 'shapefile'),
    ],
  })

  const downloadWithArgs = React.useCallback(
    (formatName: string, downloadName: string, parcelSelection: Parcel[]) => {
      const rateMapZoneFeaturesByParcelIdAndStopIndex =
        customZones.length > 0
          ? getCustomZoneAdjustedAdjustedVigorZonesByParcelIdAndStopIndex(
              customZones ?? []
            )
          : getRateMapZoneFeaturesByParcelIdAndStopIndex()
      download(
        formatName,
        downloadName,
        parcelSelection,
        rateMapZoneFeaturesByParcelIdAndStopIndex,
        customZones
      )
    },
    [
      download,
      getCustomZoneAdjustedAdjustedVigorZonesByParcelIdAndStopIndex,
      getRateMapZoneFeaturesByParcelIdAndStopIndex,
      customZones,
    ]
  )

  return [downloadWithArgs]
}
