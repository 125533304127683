import * as React from 'react'

import {
  FormLabel,
  Icon,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'

import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import i18n, { keys } from '../../i18n'
import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { toggleVisualization, updateProductSettings } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'
import { selectProductVisualizations } from '../store/selectors/selectProductVisualizations'
import { VisualizationMode } from '../store/types'
import DotSize from './DotSize'
import Grouping from './Grouping'
import Threshold from './Threshold'

interface Props {
  simple?: boolean
}

class Visualization extends React.PureComponent<
  Props & ReduxProps & AppDispatchProps & WithStyles<typeof styles>
> {
  state = {
    visOptionsOpen: false,
  }

  render() {
    const { settings, visualizations, classes } = this.props

    if (!settings || visualizations.length === 0) {
      return null
    }

    if (this.props.simple) {
      if (visualizations.length === 1) {
        return null
      }

      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Tooltip title={i18n.t(keys.visualization.visualization)}>
            <IconButton onClick={this.toggleVisualization} size="large">
              <Icon>swap_horiz</Icon>
            </IconButton>
          </Tooltip>
        </div>
      )
    }

    return (
      <React.Fragment>
        <FormLabel sx={{ fontSize: 12 }}>
          {i18n.t(keys.colourProfileType)}
        </FormLabel>
        <Select
          className={classes.select}
          value={settings.visualization}
          onChange={this.handleChangeVisualization}
          fullWidth
        >
          <MenuItem
            value="absolute"
            disabled={!visualizations.includes('absolute')}
          >
            {i18n.t(keys.visualization.absolute)}
          </MenuItem>
          <MenuItem
            value="relative"
            disabled={!visualizations.includes('relative')}
          >
            {i18n.t(keys.visualization.relative)}
          </MenuItem>
          <MenuItem
            value="threshold"
            disabled={!visualizations.includes('threshold')}
          >
            {i18n.t(keys.visualization.threshold)}
          </MenuItem>
        </Select>
        <Grouping />
        <Threshold />
        <DotSize />
      </React.Fragment>
    )
  }

  handleCloseMenu = () => {
    this.setState({ visOptionsOpen: false })
  }

  toggleVisualization = (ev: React.SyntheticEvent) => {
    ev.preventDefault()

    const {
      productId,
      dispatch,
      settings: { visualization },
    } = this.props

    dispatch(
      toggleVisualization({ productId, currentVisualization: visualization })
    )
  }

  handleChangeVisualization = (event: SelectChangeEvent) => {
    const visualization = event.target.value as VisualizationMode

    const { productId, dispatch } = this.props

    dispatch(updateProductSettings({ productId, settings: { visualization } }))
  }

  handleBlockByBlockChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { productId, dispatch } = this.props

    dispatch(
      updateProductSettings({
        productId,
        settings: { blockByBlockAverage: event.target.checked },
      })
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    select: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1 / 2),
    },
    menu: {
      overflow: 'hidden',
      top: 0,
      marginLeft: 19,
    },
    sticky: {
      position: 'sticky',
      zIndex: 10000,
      backgroundColor: theme.palette.background.paper,
      opacity: 1,
    },
    // MenuItem selected moves the menu, so adding our our selectedItem class
    selectedItem: {
      backgroundColor: `rgba(255, 255, 255, 0.2)`,
    },
    top: {
      top: 0,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
  })

const mapState = (state: RootStore) => {
  const productId = selectSelectedLegendProductId(state)!

  return {
    productId,
    settings: selectActiveProductSettings(state),
    visualizations: selectProductVisualizations(state)[productId] ?? [],
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(Visualization)
)
