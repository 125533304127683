import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grow,
  MenuItem,
  Select,
  Slide,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import { Sample } from '../../../graphql/types'
import useAsync from '../../../hooks/useAsync'
import i18n, { keys } from '../../../i18n'
import { LoadingControlBackdrop } from '../../../UI/Loading/LoadingControlBackdrop'
import { fetchSample } from '../queries'
import {
  SampleFieldWithValue,
  initialState,
  reducer,
} from './sampleFormReducer'

interface Props {
  open: boolean
  sampleId?: string
  onSave?: (sample: Pick<Sample, 'id' | 'content'>) => void
  onCancel?: () => void
}

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
`

const TemplateFieldCard = styled(Card)(({ theme }) => ({
  '& .MuiCardHeader-root': {
    padding: 0,
    '& .MuiCardHeader-title': {
      ...theme.typography.caption,
    },
  },
  '& .MuiCardContent-root': {
    padding: 0,
  },
}))

export const SampleForm = ({ open, sampleId, onSave, onCancel }: Props) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [fetchedSample] = useAsync(fetchSample, [sampleId])

  const sample = React.useMemo(
    () => ({
      ...fetchedSample.result?.data,
    }),
    [fetchedSample.result?.data]
  )
  const [sampleFormState, sampleFormDispatch] = React.useReducer(
    reducer,
    initialState
  )

  const handleSave = async () => {
    onSave?.({ id: sampleId!, content: sampleFormState.content })
  }

  const handleCancel = () => {
    onCancel?.()
  }

  React.useEffect(() => {
    if (!open) {
      setTimeout(() => {
        sampleFormDispatch({ type: 'reset' })
      }, theme.transitions.duration.leavingScreen)
    }
  }, [theme.transitions.duration.leavingScreen, open])

  const renderField = (fieldTitle: string, field: SampleFieldWithValue) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <FormControlLabel
            label={i18n.t(`noteForm.reservedNames.${fieldTitle}`, {
              defaultValue: fieldTitle,
            })}
            sx={{
              '& .MuiTypography-root': {
                fontSize: 12,
              },
            }}
            key={fieldTitle}
            control={
              <Checkbox
                sx={{ mx: 1, my: 0.5, p: 0 }}
                checked={(field.value as boolean) ?? false}
                onChange={() =>
                  sampleFormDispatch({
                    type: 'setSampleContent',
                    field,
                    value: !field.value,
                    title: fieldTitle,
                  })
                }
              />
            }
          />
        )
      case 'checkboxes':
        const selectionSet = new Set((field.value as string[]) ?? [])

        return (
          <FormControl component="fieldset" variant="outlined">
            <FormGroup>
              {field.options?.map((option) => (
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 12,
                    },
                  }}
                  key={`${fieldTitle}_${option}`}
                  control={
                    <Checkbox
                      sx={{ mx: 1, my: 0.5, p: 0 }}
                      checked={selectionSet.has(option)}
                      onChange={() =>
                        sampleFormDispatch({
                          type: 'setSampleCheckboxesContent',
                          field,
                          value: option,
                          title: fieldTitle,
                        })
                      }
                    />
                  }
                  label={i18n.t(`noteForm.reservedNames.${option}`, {
                    defaultValue: option,
                  })}
                />
              ))}
            </FormGroup>
          </FormControl>
        )
      case 'dropdown':
        return (
          <Select
            fullWidth
            variant="outlined"
            value={field.value}
            sx={{ marginTop: 1 }}
            onChange={(ev) =>
              sampleFormDispatch({
                type: 'setSampleContent',
                field,
                title: fieldTitle,
                value: ev.target.value,
              })
            }
          >
            {field.options?.map((option) => (
              <MenuItem key={`${fieldTitle}_${option}`} value={option}>
                {i18n.t(`noteForm.reservedNames.${option}`, {
                  defaultValue: option,
                })}
              </MenuItem>
            ))}
          </Select>
        )
      case 'number':
        return (
          <TextField
            fullWidth
            key={fieldTitle}
            value={field.value ?? ''}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ paddingTop: 1 }}
            onChange={(ev) =>
              sampleFormDispatch({
                type: 'setSampleContent',
                field,
                title: fieldTitle,
                value: Number(ev.target.value),
              })
            }
          />
        )
      case 'text':
        return (
          <FormControl key={fieldTitle} fullWidth>
            <TextField
              autoComplete="off"
              autoFocus
              multiline
              fullWidth
              variant="outlined"
              value={field.value ?? ''}
              onChange={(ev) =>
                sampleFormDispatch({
                  type: 'setSampleContent',
                  field,
                  title: fieldTitle,
                  value: ev.target.value,
                })
              }
              sx={{
                paddingTop: 1,
                [`& .MuiInputBase-input`]: {
                  px: 1,
                  py: 1.5,
                },
                [`& .MuiOutlinedInput-notchedOutline`]: {
                  backgroundColor: '#00000033',
                },
                [`&:not(.Mui-focused,:hover)`]: {
                  [`& .MuiOutlinedInput-notchedOutline`]: {
                    border: 'none',
                  },
                },
                [`& .MuiInputBase-root.MuiOutlinedInput-root`]: {
                  padding: 0,
                },
              }}
            />
          </FormControl>
        )
    }
  }

  React.useEffect(() => {
    sampleFormDispatch({
      type: 'setForm',
      formContent: {
        content: sample?.content as Record<string, SampleFieldWithValue>,
      },
    })
  }, [sample])

  return (
    <Dialog
      open={open}
      TransitionComponent={isMobile ? Slide : Grow}
      fullWidth={isMobile}
      fullScreen={isMobile}
    >
      <DialogTitle>
        <Title>{i18n.t(keys.samplePlanEnterInfoTitle)}</Title>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <LoadingControlBackdrop open={fetchedSample.status === 'pending'}>
          <Stack direction="column" gap={1} sx={{ minWidth: 375 }}>
            {Object.entries(sampleFormState.content ?? {})?.map(
              ([fieldTitle, field]) => (
                <TemplateFieldCard key={fieldTitle} elevation={0}>
                  <CardHeader
                    title={i18n.t(`noteForm.reservedNames.${fieldTitle}`, {
                      defaultValue: fieldTitle,
                    })}
                  />
                  <CardContent>{renderField(fieldTitle, field)}</CardContent>
                </TemplateFieldCard>
              )
            )}
          </Stack>
        </LoadingControlBackdrop>
      </DialogContent>
      <DialogActions sx={{ px: 2, paddingBottom: 2 }}>
        <Button
          onClick={handleCancel}
          color="secondary"
          style={{ color: theme.palette.text.primary }}
          disableElevation
        >
          {i18n.t(keys.generic.cancel)}
        </Button>

        <Button color="primary" onClick={handleSave}>
          {i18n.t(keys.generic.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
