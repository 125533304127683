import React from 'react'
import { FixedSizeList } from 'react-window'
import { Item, Level, LevelInfo } from './types'

export interface DrillDownMenuContextState {
  levels: Level[]
  listRef:
    | React.RefObject<
        FixedSizeList<{
          items: Item[] | undefined
          levelIndex: number
          setCurrentLevelInfo: (levelInfo: LevelInfo) => void
          currentLevelInfo: LevelInfo
          level: Level
          levels: Level[]
        }>
      >
    | undefined
  loadedItems: Item[]
  currentLevel: Level
  currentLevelInfo: LevelInfo
  setCurrentLevelInfo: React.Dispatch<React.SetStateAction<LevelInfo>>
  setLoadedItems: React.Dispatch<React.SetStateAction<Item[] | undefined>>
}

interface Props {
  children: React.ReactNode
  levels: Level[]
}

export const DrillDownMenuContext =
  React.createContext<DrillDownMenuContextState>({
    levels: [],
    listRef: undefined,
    loadedItems: [],
    currentLevel: { id: 0, ItemComponent: () => null },
    currentLevelInfo: { index: 0, parentHistory: [] },
    setCurrentLevelInfo: () => {},
    setLoadedItems: () => [],
  })

export const useDrillDownMenuContext = () =>
  React.useContext(DrillDownMenuContext)

export const DrillDownMenuContextProvider = ({ children, levels }: Props) => {
  const listRef = React.createRef<
    FixedSizeList<{
      items: Item[] | undefined
      levelIndex: number
      setCurrentLevelInfo: (levelInfo: LevelInfo) => void
      currentLevelInfo: LevelInfo
      level: Level
      levels: Level[]
    }>
  >()
  const [currentLevelInfo, setCurrentLevelInfo] = React.useState<LevelInfo>({
    index: 0,
    parentHistory: levels.map(() => undefined),
  })

  const currentLevel = React.useMemo(
    () => levels[currentLevelInfo.index],
    [currentLevelInfo.index, levels]
  )

  const [loadedItems, setLoadedItems] = React.useState<Item[]>([])

  return (
    <DrillDownMenuContext.Provider
      value={{
        levels,
        listRef,
        loadedItems,
        currentLevel,
        currentLevelInfo,
        setCurrentLevelInfo,
        setLoadedItems,
      }}
    >
      {children}
    </DrillDownMenuContext.Provider>
  )
}
