import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import { DraggablePaper } from '../UI/DraggablePaper'
import { Media } from '../graphql/types'
import { useFeatureFlag } from '../hooks/useFeatureFlag'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import { MediaAttachmentsModal } from '../media/MediaAttachmentsModal'
import { MediaFile, MediaSelector } from '../media/MediaSelector'
import { SelectProject } from '../projects/SelectProject'
import { updatePreferences } from '../redux/preferences/redux'
import { WindowPosition } from '../redux/preferences/types'
import { setToggle } from '../util/setToggle'
import {
  Field,
  Note,
  NoteFormV2,
  NoteWithTemplate,
  OrgFeatureGeometry,
} from '../vvapi/models'
import { useNoteTemplates } from './NotesContext'
import { PinPointButtonPress } from './PinPointButtonPress'
import { actions } from './redux'

export const TemplateFieldCard = styled(Card)(({ theme }) => ({
  '& .MuiCardHeader-root': {
    padding: theme.spacing(1),
    '& .MuiCardHeader-title': {
      ...theme.typography.caption,
    },
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(1),
  },
}))

const GeneralTextField = styled(TextField)(({ theme }) => ({
  mx: 0,
  '& textarea': {
    height: '100% !important',
  },
  '& fieldset': {
    borderWidth: 0,
    background: theme.palette.background.default,
  },
  '& .MuiInputAdornment-root': {
    zIndex: 2,
  },
  '& .MuiInputBase-root': {
    height: '100%',
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.grey[700],
      borderWidth: 1,
    },
  },
  '& .MuiInputBase-input': {
    p: 0.5,
    zIndex: 2,
  },
}))

export const OutlinedSelect = styled(Select)(({ theme }) => ({
  mx: 0,
  '& .MuiSelect-select': {
    height: '100% !important',
  },
  '& fieldset': {
    borderWidth: 0,
    background: theme.palette.background.default,
  },
  '& .MuiSelect-icon': {
    color: theme.palette.text.primary,
    zIndex: 1,
  },
  '& .MuiInputBase-root': {
    height: '100%',
    '&.Mui-focused fieldset': {
      borderWidth: 1,
    },
  },
  '& .MuiInputBase-input': {
    p: 0.5,
    zIndex: 2,
  },
}))

const TemplateTitle = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '14.4px',
  letterSpacing: '-0.035em',
  textAlign: 'left',
  marginBottom: -5,
}))

export const CompactCardContent = styled(CardContent)(({ theme }) => ({
  ['&.MuiCardContent-root:last-child']: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1),
  },
}))

export const CompactCardHeader = styled(CardHeader)(({ theme }) => ({
  ['&.MuiCardHeader-root']: {
    paddingTop: theme.spacing(1 / 2),
    paddingBottom: theme.spacing(1 / 4),
  },
}))

export interface FieldWithValue extends Field {
  value: string | number | string[] | boolean
}

interface Props {
  open: boolean
  initialNote?: NoteWithTemplate
  mode?: 'create' | 'edit'
  onCreateNote?: (
    note: Pick<Note, 'content' | 'templateId' | 'projectId' | 'noteMedia'>
  ) => void
  onEditNote?: (note: Pick<Note, 'content' | 'projectId' | 'noteMedia'>) => void
  onEditLocation?: (
    note: Pick<Note, 'content' | 'projectId' | 'noteMedia'>
  ) => void
  onCancel: () => void
  onDelete?: () => void
}

interface MediaWithLoadedState extends Media {
  isLoaded: boolean
}

interface NoteState {
  isGeneral: boolean
  isPinPointButtonPress: boolean
  selectedTemplateId?: string
  pinColor?: string
  templateName?: string
  content: Record<string, FieldWithValue>
  selectedProjectId?: string
  noteMedia?: Record<string, MediaWithLoadedState>
  id?: string
  geometry?: OrgFeatureGeometry
  isDirty: boolean
}

const GENERAL_TEMPLATE_ID = '00000000-0000-0000-0000-000000000000'
export const PIN_POINT_BUTTON_PRESS_TEMPLATE_ID =
  '00000000-0000-0000-0000-000000000004'

const INITIAL_STATE: NoteState = {
  isGeneral: true,
  isPinPointButtonPress: false,
  selectedTemplateId: GENERAL_TEMPLATE_ID,
  pinColor: '#2196F3',
  templateName: i18n.t(keys.noteForm.reservedNames.__VV__GENERAL__TEMPLATE__),
  content: { note: { type: 'text', value: '' } },
  noteMedia: {},
  isDirty: false,
}

type NoteAction =
  | {
      type: 'setNote'
      note: NoteWithTemplate
      isGeneral: boolean
      isPinPointButtonPress: boolean
    }
  | {
      type: 'setSelectedTemplate'
      template: NoteFormV2
    }
  | {
      type: 'setNoteValue'
      field: Omit<Field, 'title'>
      title: string
      value: any
    }
  | {
      type: 'setNoteCheckboxesValue'
      field: Omit<Field, 'title'>
      title: string
      value: any
    }
  | { type: 'reset' }
  | {
      type: 'setSelectedProject'
      projectId: string | undefined
      inhibitDirty?: boolean
    }
  | { type: 'addAttachments'; attachments: Media[] }
  | { type: 'attachmentLoaded'; attachmentId: string }
  | { type: 'attachmentsLoaded'; attachments: Media[] }
  | { type: 'addAttachment'; attachment: Media }
  | { type: 'removeAttachment'; attachment: Media }

const buildContentFromTemplate = (template: NoteFormV2) => {
  const content = {}

  for (const field of Object.values(template.fields)) {
    content[field.title!] = { ...field }
  }

  return content
}

const noteReducer = (state: NoteState, action: NoteAction) => {
  if (action.type === 'reset') {
    return INITIAL_STATE
  }
  if (action.type === 'setSelectedTemplate') {
    return {
      ...state,
      selectedTemplateId: action.template.id,
      isGeneral: action.template.id === GENERAL_TEMPLATE_ID,
      isPinPointButtonPress:
        action.template.id === PIN_POINT_BUTTON_PRESS_TEMPLATE_ID,
      content: buildContentFromTemplate(action.template),
      pinColor: action.template.pinColor,
      templateName: action.template.name,
    }
  }
  if (action.type === 'setNoteValue') {
    return {
      ...state,
      isDirty: true,
      content: {
        ...state.content,
        [action.title]: { ...action.field, value: action.value },
      },
    }
  }
  if (action.type === 'setNoteCheckboxesValue') {
    const selectionSet = new Set(
      (state.content[action.title]?.value as string[]) ?? []
    )

    const value = Array.from(setToggle(selectionSet, action.value))

    return {
      ...state,
      isDirty: true,
      content: {
        ...state.content,
        [action.title]: { ...action.field, value },
      },
    }
  }

  if (action.type === 'setNote') {
    return {
      content: action.note.content as Record<string, FieldWithValue>,
      id: action.note.id,
      geometry: action.note.feature.geometry,
      isGeneral: action.isGeneral,
      isPinPointButtonPress: action.isPinPointButtonPress,
      pinColor: action.note.pinColor,
      templateName: action.note.templateName,
      selectedProjectId: action.note.projectId,
      noteMedia: action.note.noteMedia?.reduce((acc, media) => {
        if (!media.media) {
          return acc
        }
        acc[media.media.id] = { ...media.media, isLoaded: true }
        return acc
      }, {} as Record<string, MediaWithLoadedState>),
    } as NoteState
  }

  if (action.type === 'setSelectedProject') {
    return {
      ...state,
      isDirty: action.inhibitDirty ? state.isDirty : true,
      selectedProjectId: action.projectId,
    }
  }

  if (action.type === 'addAttachments') {
    return {
      ...state,
      isDirty: true,
      noteMedia: {
        ...state.noteMedia,
        ...action.attachments.reduce((acc, attachment) => {
          acc[attachment.id] = { ...attachment, isLoaded: false }
          return acc
        }, {} as Record<string, MediaWithLoadedState>),
      },
    }
  }

  if (action.type === 'removeAttachment') {
    const newNoteMedia = { ...state.noteMedia }
    delete newNoteMedia[action.attachment.id]
    return {
      ...state,
      isDirty: true,
      noteMedia: newNoteMedia,
    }
  }

  if (action.type === 'addAttachment') {
    const newNoteMedia = { ...state.noteMedia }
    newNoteMedia[action.attachment.id] = {
      ...action.attachment,
      isLoaded: false,
    }

    return {
      ...state,
      isDirty: true,
      noteMedia: newNoteMedia,
    }
  }
  if (action.type === 'attachmentLoaded') {
    const newNoteMedia = { ...state.noteMedia }
    if (newNoteMedia[action.attachmentId]) {
      newNoteMedia[action.attachmentId].isLoaded = true

      return {
        ...state,
        noteMedia: newNoteMedia,
      }
    }

    return state
  }
  if (action.type === 'attachmentsLoaded') {
    const newNoteMedia = { ...state.noteMedia }
    for (const attachment of action.attachments) {
      if (newNoteMedia[attachment.id]) {
        newNoteMedia[attachment.id].isLoaded = true
      }
    }

    return {
      ...state,
      noteMedia: newNoteMedia,
    }
  }

  return state
}

const NoPointerBackdrop = styled(Backdrop)({
  background: 'transparent',
  pointerEvents: 'none',
})

export const NoteForm = ({
  open,
  initialNote,
  onCreateNote,
  onCancel,
  onEditLocation,
  onEditNote,
  onDelete,
  mode = 'create',
}: Props) => {
  const theme = useTheme()
  const [selectedMediaId, setSelectedMediaId] = React.useState<
    string | undefined
  >()
  const { featureEnabled: photosEnabled } = useFeatureFlag({
    featureFlagId: 'photos',
  })
  const [state, dispatch] = useRedux()
  const [noteState, noteDispatch] = React.useReducer(
    noteReducer,
    INITIAL_STATE,
    () => {
      if (initialNote) {
        return {
          content: initialNote.content as Record<string, FieldWithValue>,
          noteMedia: Object.values(initialNote.noteMedia ?? {}).reduce(
            (acc, media) => {
              if (!media.media) {
                return acc
              }
              acc[media.media.id] = { ...media.media, isLoaded: true }

              return acc
            },
            {} as Record<string, MediaWithLoadedState>
          ),
        } as NoteState
      } else {
        return INITIAL_STATE
      }
    }
  )

  const allAttachmentsLoaded = React.useMemo(() => {
    if (
      noteState.noteMedia === undefined ||
      Object.keys(noteState.noteMedia).length === 0
    ) {
      return true
    }
    return Object.values(noteState.noteMedia).every((media) => media.isLoaded)
  }, [noteState.noteMedia])

  React.useEffect(() => {
    if (!open && !initialNote) {
      setTimeout(() => {
        noteDispatch({ type: 'reset' })
      }, theme.transitions.duration.leavingScreen)
    }
  }, [theme.transitions.duration.leavingScreen, open, initialNote])

  const [noteTemplatesAsync] = useNoteTemplates()

  const templatesById = React.useMemo(
    () => noteTemplatesAsync?.result ?? ({} as Record<string, NoteFormV2>),
    [noteTemplatesAsync.result]
  )

  const generalTemplate = React.useMemo(
    () => templatesById[GENERAL_TEMPLATE_ID],
    [templatesById]
  )

  const pinPointTemplate = React.useMemo(
    () => templatesById[PIN_POINT_BUTTON_PRESS_TEMPLATE_ID],
    [templatesById]
  )

  React.useEffect(() => {
    if (initialNote && open) {
      noteDispatch({
        type: 'setNote',
        note: initialNote,
        isGeneral: initialNote.templateName === generalTemplate.name,
        isPinPointButtonPress:
          initialNote.templateName === pinPointTemplate.name,
      })
      const files = Object.values(initialNote.noteMedia ?? {}).reduce(
        (acc, media) => {
          if (!media.media) {
            return acc
          }
          acc[media.media.id] = { media: media.media }
          return acc
        },
        {} as Record<string, MediaFile>
      )
      setCurrentFiles(files)
    }
  }, [initialNote, generalTemplate])

  const [currentFiles, setCurrentFiles] = React.useState<
    Record<string, MediaFile>
  >({})

  const onDeleteMedia = async (media: Media) => {
    noteDispatch({ type: 'removeAttachment', attachment: media })
  }

  const onUploaded = async () => {
    // noteDispatch({ type: 'addAttachments', attachments: media })
  }

  const onAddMedia = async (media: Media) => {
    noteDispatch({ type: 'addAttachment', attachment: media })
  }

  const onLoadImage = async (media: Media[]) => {
    noteDispatch({ type: 'attachmentsLoaded', attachments: media })
  }

  React.useEffect(() => {
    if (open) {
      if (mode === 'create' && state.notes.lastUsedTemplateId) {
        noteDispatch({
          type: 'setSelectedTemplate',
          template: templatesById[state.notes.lastUsedTemplateId],
        })
      }
      if (mode === 'create' && state.notes.lastUsedProjectId) {
        noteDispatch({
          type: 'setSelectedProject',
          projectId: state.notes.lastUsedProjectId,
          inhibitDirty: true,
        })
      }
      if (!initialNote) {
        // If creating a new note, clear any existing media
        setCurrentFiles({})
      }
    }
  }, [open])

  const handleSaveEditNote = () => {
    onEditNote?.({
      content: noteState.content,
      projectId: noteState.selectedProjectId,
      noteMedia: Object.values(noteState.noteMedia ?? {}).map((media) => ({
        mediaId: media.id,
        Media: media,
      })),
    })
    setCurrentFiles({})
  }

  const handleCreateNewNote = () => {
    const template = noteState.selectedTemplateId
      ? templatesById?.[noteState.selectedTemplateId]
      : undefined
    if (!template) {
      return
    }

    onCreateNote?.({
      content: noteState.content,
      templateId: template.id,
      projectId: noteState.selectedProjectId,
      noteMedia: Object.values(noteState.noteMedia ?? {}).map((media) => ({
        // note id is attached after creation of note.
        mediaId: media.id,
        Media: media,
      })),
    })
    setCurrentFiles({})
  }

  const handleEditLocation = () => {
    onEditLocation?.({
      content: noteState.content,
      projectId: noteState.selectedProjectId,
      noteMedia: Object.values(noteState.noteMedia ?? {}).map((media) => ({
        // note id is attached after creation of note.
        mediaId: media.id,
        Media: media,
      })),
    })
  }

  const renderTemplateForm = () => {
    // general template has special rendering
    if (noteState.isGeneral && !!generalTemplate) {
      const [noteField] = generalTemplate?.fields ?? []

      return (
        <GeneralTextField
          required
          autoComplete="off"
          autoFocus={mode === 'create'}
          multiline
          variant="outlined"
          placeholder={i18n.t(keys.notes.general.placeholder)}
          sx={{ height: 130, width: '100%' }}
          value={noteState.content[noteField.title!]?.value ?? ''}
          onChange={(ev) => {
            noteDispatch({
              type: 'setNoteValue',
              field: noteField,
              title: 'note',
              value: ev.target.value,
            })
          }}
        />
      )
    }

    if (noteState.isPinPointButtonPress && !!pinPointTemplate) {
      return <PinPointButtonPress content={noteState.content} />
    }

    return (
      <Stack spacing={1}>
        {Object.entries(noteState.content)?.map(([fieldKey, field]) => {
          const translatedTitle = i18n.t(`noteForm.reservedNames.${fieldKey}`, {
            defaultValue: fieldKey,
          })

          return (
            <TemplateFieldCard
              key={fieldKey}
              sx={{ background: theme.palette.background.default }}
              elevation={0}
            >
              <CompactCardHeader
                title={translatedTitle}
                titleTypographyProps={{
                  fontWeight: '700 !important',
                  letterSpacing: 0.4,
                  px: 1 / 2,
                }}
              />
              <CompactCardContent>
                {renderField(fieldKey, translatedTitle, field)}
              </CompactCardContent>
            </TemplateFieldCard>
          )
        })}
      </Stack>
    )
  }

  const renderField = (
    fieldKey: string,
    fieldTitle: string,
    field: FieldWithValue
  ) => {
    switch (field.type) {
      case 'checkbox':
        return (
          <FormControlLabel
            label={fieldTitle}
            sx={{
              px: 1,
              '& .MuiTypography-root': {
                fontSize: 12,
              },
            }}
            key={fieldKey}
            control={
              <Checkbox
                sx={{ mx: 1, my: 0.5, p: 0 }}
                checked={(field.value as boolean) ?? false}
                onChange={() =>
                  noteDispatch({
                    type: 'setNoteValue',
                    field,
                    value: !field.value,
                    title: fieldKey,
                  })
                }
              />
            }
          />
        )
      case 'checkboxes':
        const selectionSet = new Set((field.value as string[]) ?? [])

        return (
          <FormControl component="fieldset" variant="outlined" sx={{ px: 1 }}>
            <FormGroup>
              {field.options?.map((option) => (
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 12,
                    },
                  }}
                  key={`${fieldKey}_${option}`}
                  control={
                    <Checkbox
                      sx={{ mx: 1, my: 0.5, p: 0 }}
                      checked={selectionSet.has(option)}
                      onChange={() =>
                        noteDispatch({
                          type: 'setNoteCheckboxesValue',
                          field,
                          value: option,
                          title: fieldKey,
                        })
                      }
                    />
                  }
                  label={i18n.t(`noteForm.reservedNames.${option}`, {
                    defaultValue: option,
                  })}
                />
              ))}
            </FormGroup>
          </FormControl>
        )
      case 'dropdown':
        return (
          <Select
            fullWidth
            variant="outlined"
            sx={{ ['& .MuiSelect-select']: { p: 1 } }}
            value={field.value}
            onChange={(ev) =>
              noteDispatch({
                type: 'setNoteValue',
                field,
                title: fieldKey,
                value: ev.target.value,
              })
            }
          >
            {field.options?.map((option) => (
              <MenuItem key={`${fieldKey}_${option}`} value={option}>
                {i18n.t(`noteForm.reservedNames.${option}`, {
                  defaultValue: option,
                })}
              </MenuItem>
            ))}
          </Select>
        )
      case 'number':
        return (
          <TextField
            sx={{ ['& .MuiOutlinedInput-input']: { p: 1 } }}
            variant="outlined"
            fullWidth
            key={fieldKey}
            value={field.value ?? ''}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(ev) =>
              noteDispatch({
                type: 'setNoteValue',
                field,
                title: fieldKey,
                value: Number(ev.target.value),
              })
            }
          />
        )
      case 'text':
        return (
          <FormControl key={fieldKey} fullWidth>
            <TextField
              sx={{ ['& .MuiInputBase-root']: { p: 1 } }}
              autoComplete="off"
              autoFocus={mode === 'create'}
              multiline
              fullWidth
              variant="outlined"
              value={field.value ?? ''}
              onChange={(ev) =>
                noteDispatch({
                  type: 'setNoteValue',
                  field,
                  title: fieldKey,
                  value: ev.target.value,
                })
              }
            />
          </FormControl>
        )
    }
  }

  const renderEditActions = () => {
    return (
      <Stack
        direction="row"
        width="100%"
        alignItems="center"
        justifyContent="end"
        spacing={1}
      >
        <Button
          sx={{ minWidth: 0 }}
          variant="contained"
          fullWidth
          color="primary"
          disabled={!noteState.isDirty || !allAttachmentsLoaded}
          onClick={handleSaveEditNote}
        >
          {i18n.t(keys.notes.notesPopup.save)}
        </Button>

        <Button
          sx={{ minWidth: 0 }}
          variant="contained"
          fullWidth
          disabled={noteState.isPinPointButtonPress}
          onClick={handleEditLocation}
        >
          {i18n.t(keys.notes.notesPopup.editLocation)}
        </Button>
        <IconButton onClick={onDelete}>
          <Icon>delete</Icon>
        </IconButton>
      </Stack>
    )
  }

  const renderNewNoteActions = () => (
    <Stack spacing={1} sx={{ width: '100%' }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={state.notes.createAnother}
              onChange={() =>
                dispatch(actions.setCreateAnother(!state.notes.createAnother))
              }
            />
          }
          label={i18n.t(keys.notes.notesPopup.createAnother)}
        />
      </FormGroup>

      <Button
        variant="contained"
        fullWidth
        disabled={!noteState.isDirty || !allAttachmentsLoaded}
        color="primary"
        onClick={handleCreateNewNote}
      >
        {i18n.t(keys.generic.create)}
      </Button>
    </Stack>
  )

  const handleTemplateChange = (ev: SelectChangeEvent) => {
    noteDispatch({
      type: 'setSelectedTemplate',
      template: templatesById[ev.target.value],
    })
  }

  const handleCancel = () => {
    onCancel()
    dispatch(actions.setCreateAnother(false))
  }

  const handleDialogPositionUpdate = (position: WindowPosition) => {
    dispatch(updatePreferences({ notesWindowPosition: position }))
  }

  return (
    <>
      <Dialog
        open={open}
        sx={{ pointerEvents: 'none' }}
        maxWidth="sm"
        PaperComponent={DraggablePaper}
        PaperProps={{
          position: state.preferences.notesWindowPosition,
          onPositionUpdate: handleDialogPositionUpdate,
        }}
        slots={{ backdrop: NoPointerBackdrop }}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle id="draggable-dialog-title" sx={{ cursor: 'move', px: 2 }}>
          <Stack direction="row" justifyContent="space-between">
            {mode === 'edit' ? (
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {i18n.t(keys.notesTab)}
              </Typography>
            ) : (
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                {i18n.t(keys.noteForm.createNote)}
              </Typography>
            )}
            <IconButton
              onClick={handleCancel}
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.text.primary,
              }}
            >
              <Icon>close</Icon>
            </IconButton>
          </Stack>
        </DialogTitle>
        <Divider
          sx={{ borderColor: 'white', borderWidth: 1, border: '.1px solid' }}
        />
        <DialogContent sx={{ paddingBottom: 0, minWidth: 350, px: 2 }}>
          <Stack spacing={2}>
            {mode === 'edit' ? (
              noteState.isPinPointButtonPress ? (
                <Typography variant="h6">
                  {(noteState.content as any).label!.value}
                </Typography>
              ) : (
                <Stack direction="column" gap={1}>
                  <TemplateTitle>
                    {i18n.t(keys.noteForm.templateTitle)}
                  </TemplateTitle>
                  <OutlinedSelect
                    disabled
                    variant="outlined"
                    label="Template"
                    value="NOT UNDEFINED"
                    size="small"
                    renderValue={() => (
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Box
                          sx={{
                            height: 12,
                            width: 12,
                            borderRadius: 12,
                            background: `${noteState.pinColor}`,
                          }}
                        />
                        <ListItemText
                          primary={i18n.t(
                            `noteForm.reservedNames.${noteState.templateName}`,
                            {
                              defaultValue: noteState.templateName,
                            }
                          )}
                        />
                      </Stack>
                    )}
                  >
                    <MenuItem value="NOT UNDEFINED" />
                  </OutlinedSelect>
                </Stack>
              )
            ) : (
              <Stack direction="column" gap={1}>
                <TemplateTitle>
                  {i18n.t(keys.noteForm.templateTitle)}
                </TemplateTitle>
                <OutlinedSelect
                  variant="outlined"
                  name="template"
                  size="small"
                  renderValue={() => (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Box
                        sx={{
                          height: 12,
                          width: 12,
                          borderRadius: 12,
                          background: `${
                            noteState.selectedTemplateId
                              ? templatesById?.[noteState.selectedTemplateId]
                                  ?.pinColor
                              : undefined
                          }`,
                        }}
                      />
                      <ListItemText
                        primary={i18n.t(
                          `noteForm.reservedNames.${
                            noteState.selectedTemplateId
                              ? templatesById?.[noteState.selectedTemplateId]
                                  ?.name
                              : undefined
                          }`,
                          {
                            defaultValue: noteState.selectedTemplateId
                              ? templatesById?.[noteState.selectedTemplateId]
                                  ?.name
                              : undefined,
                          }
                        )}
                      />
                    </Stack>
                  )}
                  value={noteState.selectedTemplateId ?? ''}
                  onChange={handleTemplateChange}
                >
                  {Object.values(templatesById).map((template) => {
                    if (template.id === PIN_POINT_BUTTON_PRESS_TEMPLATE_ID) {
                      return null
                    }

                    return (
                      <MenuItem key={template.id} value={template.id}>
                        <ListItemIcon>
                          <Box
                            sx={{
                              height: 12,
                              width: 12,
                              borderRadius: 12,
                              background: `${template.pinColor}`,
                            }}
                          />
                        </ListItemIcon>

                        <ListItemText
                          primary={i18n.t(
                            `noteForm.reservedNames.${template.name}`,
                            {
                              defaultValue: template.name,
                            }
                          )}
                        />
                      </MenuItem>
                    )
                  })}
                </OutlinedSelect>
              </Stack>
            )}
            <SelectProject
              selectedProjectId={noteState.selectedProjectId}
              type="notes"
              onProjectChange={(projectId) => {
                noteDispatch({
                  type: 'setSelectedProject',
                  projectId,
                  inhibitDirty: mode === 'create',
                })
              }}
            />
            {photosEnabled && (
              <MediaSelector
                currentFiles={currentFiles}
                setCurrentFiles={setCurrentFiles}
                onUploaded={onUploaded}
                onAddMedia={onAddMedia}
                onLoadImage={onLoadImage}
                onDeleteMedia={onDeleteMedia}
                onClickMedia={(mediaId) => setSelectedMediaId(mediaId)}
              />
            )}

            {renderTemplateForm()}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 2 }}>
          {mode === 'edit' ? renderEditActions() : renderNewNoteActions()}
        </DialogActions>
      </Dialog>

      <MediaAttachmentsModal
        selectedMediaId={selectedMediaId}
        setSelectedMediaId={setSelectedMediaId}
        note={{
          pinColor: noteState.pinColor ?? '#2196F3',
          templateName: noteState.templateName ?? '',
          id: noteState.id ?? '',
        }}
        geometry={noteState.geometry}
        mediaFiles={currentFiles}
      />
    </>
  )
}
