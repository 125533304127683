import { FormControl, ListItem, styled } from '@mui/material'

export const StyledListItem = styled(ListItem)({
  paddingBottom: '20px',
})

export const StyledFormControl = styled(FormControl)({
  '& .MuiInputLabel-root': {
    position: 'relative',
    transform: 'none',
    marginBottom: '4px',
    fontSize: '12px',
  },
  '& .MuiSelect-select': {
    paddingTop: '0px',
    paddingBottom: '8px',
  },
  '& .MuiInputBase-root': {
    marginTop: '0px',
  },
})
