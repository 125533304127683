import { Box } from '@mui/material'
import { styled } from '@mui/styles'
import * as React from 'react'
import { PureComponent } from 'react'
import LayerDrawerSubheader from '../postgis/LayerDrawer/LayerDrawerSubheader'
import { selectSelectedProduct } from '../postgis/selectSelectedProduct'
import ColorProfileSelect from '../ProductSettings/UI/ColorProfileSelect'
import Opacity from '../ProductSettings/UI/Opacity'
import Visualization from '../ProductSettings/UI/Visualization'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'

const ColorSchemeContainer = styled(Box)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(3, 2),
  paddingTop: theme.spacing(2),
}))

class ColorSchemeList extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    const { selectedProduct } = this.props

    if (!selectedProduct) {
      return null
    }

    return (
      <Box>
        <LayerDrawerSubheader>{selectedProduct.name}</LayerDrawerSubheader>
        <ColorSchemeContainer>
          <Opacity />
          <ColorProfileSelect />
          <Visualization />
        </ColorSchemeContainer>
      </Box>
    )
  }
}

const mapState = (state: RootStore) => ({
  selectedProduct: selectSelectedProduct(state),
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  ColorSchemeList
)
