import * as React from 'react'
import { ConfirmationModal } from '../app/ConfirmationModal/ConfirmationModal'
import { selectDeliveryParcelsByParcelId } from '../data/selectDelivery'
import { selectOrganization } from '../data/selectOrganization'
import { client, gql } from '../graphql/client'
import { Media } from '../graphql/types'
import { useRedux } from '../hooks/useRedux'
import i18n, { keys } from '../i18n'
import FitSelectedNote from '../map/controls/FitSelectedNote'
import { useMap } from '../map/withMap'
import { showFeedback } from '../redux/notifications/redux'
import { Note, OrganizationFeature } from '../vvapi/models'
import getContainingParcelId from './getContainingParcelId'
import { NoteForm } from './NoteForm'
import { NoteGeometryDialog } from './NoteGeometryDialog'
import { actions } from './redux'
import {
  refreshListNotes,
  refreshListProjects,
  selectEditingNote,
} from './selectors'

export const NoteMapComponents = () => {
  const [state, dispatch] = useRedux()

  const { map } = useMap()

  const organization = selectOrganization(state)

  const parcelsById = selectDeliveryParcelsByParcelId(state)

  const [pendingNote, setPendingNote] = React.useState<
    | Pick<
        Note,
        'content' | 'templateId' | 'feature' | 'projectId' | 'noteMedia'
      >
    | undefined
  >()

  const [noteGeometry, setNoteGeometry] = React.useState<
    OrganizationFeature | undefined
  >()

  const [editingNoteContent, setEditingNoteContent] = React.useState<
    Note['content'] | undefined
  >()

  const { isCreatingNote, noteEditMode, deleteNoteDialog } = state.notes

  const editingNote = selectEditingNote(state)

  const getMapCenter = () => {
    if (!map) {
      return
    }

    return map?.getCenter()
  }

  const handleNewNoteCancel = () => {
    dispatch(actions.setIsCreatingNote(false))
    setPendingNote(undefined)
    setNoteGeometry(undefined)
  }

  const handleNewNoteCreate = (
    newNote: Pick<Note, 'content' | 'templateId' | 'projectId' | 'noteMedia'>
  ) => {
    const latLng = getMapCenter()
    if (!latLng) {
      return
    }

    const feature = {
      geometry: {
        type: 'Point',
        coordinates: [latLng.lng, latLng.lat],
      },
    } as OrganizationFeature

    setPendingNote({ ...newNote, feature })
    dispatch(actions.setNoteEditMode('geometry'))
  }

  const handlEditNoteCancel = () => {
    dispatch(actions.setEditingNoteId(undefined))
  }

  const handlEditNote = async (
    note: Pick<Note, 'content' | 'projectId' | 'noteMedia'>
  ) => {
    try {
      if (!editingNote) {
        return
      }
      const { noteMedia } = note
      if (noteGeometry) {
        const { id, ...feature } = noteGeometry
        const containingParcelId = getContainingParcelId(
          feature.geometry!,
          parcelsById
        )

        await client.request({
          query: gql`
            mutation EDIT_NOTE_AND_GEOMETRY(
              $noteId: uuid!
              $featureId: uuid!
              $note: Note_set_input!
              $feature: Feature_set_input!
            ) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
              update_Feature_by_pk(
                pk_columns: { id: $featureId }
                _set: $feature
              ) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: {
              content: note.content,
              parcelId: containingParcelId,
              projectId: note.projectId,
            },
            featureId: editingNote.feature.id,
            feature: feature,
          },
        })
      } else {
        await client.request<{ note: Note }>({
          query: gql`
            mutation EDIT_NOTE($noteId: uuid!, $note: Note_set_input!) {
              note: update_Note_by_pk(
                pk_columns: { id: $noteId }
                _set: $note
              ) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: {
              content: note.content,
              projectId: note.projectId ? note.projectId : null,
            },
          },
        })
      }

      // Once the note is updated, we need to update the references to the media.
      // Media is already uploaded, but references to notes needs to be updated on save.
      if (
        noteMedia &&
        editingNote &&
        noteMedia.length !== editingNote.noteMedia?.length
      ) {
        const newMedia = noteMedia?.filter(
          (nm) =>
            !editingNote.noteMedia?.some((n) => n?.media?.id === nm.mediaId)
        )
        const deletedMedia = editingNote.noteMedia?.filter(
          (nm) => !noteMedia?.some((n) => n.mediaId === nm?.media?.id)
        )

        // delete media that was removed
        if (deletedMedia && deletedMedia.length > 0) {
          // soft delete the media.
          await client.request<Media>({
            query: gql`
              mutation SOFT_DELETE_MEDIA($ids: [uuid!]!, $now: timestamptz!) {
                update_Media(
                  where: { id: { _in: $ids } }
                  _set: { deletedAt: $now }
                ) {
                  returning {
                    id
                  }
                }
              }
            `,
            variables: {
              ids: deletedMedia.map((m) => m?.media?.id),
              now: new Date().toISOString(),
            },
          })
          // hard delete the references to the media
          await client.request({
            query: gql`
              mutation DELETE_NOTE_MEDIA($ids: [uuid!]!) {
                delete_NoteMedia(where: { mediaId: { _in: $ids } }) {
                  returning {
                    noteId
                    mediaId
                  }
                }
              }
            `,
            variables: {
              ids: deletedMedia.map((m) => m?.media?.id),
            },
          })
        }

        // insert new media if any were added
        if (newMedia && newMedia.length > 0) {
          await client.request({
            query: gql`
              mutation INSERT_NOTE_MEDIA(
                $noteMedia: [NoteMedia_insert_input!]!
              ) {
                insert_NoteMedia(objects: $noteMedia) {
                  returning {
                    noteId
                    mediaId
                  }
                }
              }
            `,
            variables: {
              noteMedia: newMedia.map((m) => ({
                noteId: editingNote.id,
                mediaId: m.mediaId,
              })),
            },
          })
        }
      }

      setNoteGeometry(undefined)
      refreshListNotes()
      refreshListProjects()
      dispatch(actions.setEditingNoteId(undefined))
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  const handleSaveNote = async () => {
    try {
      if (!isCreatingNote || !pendingNote) {
        console.error(
          'Cannot save a note that aleady exists. Use edit instead.'
        )
        return
      }

      const { feature, noteMedia, ...note } = pendingNote

      const containingParcelId = getContainingParcelId(
        noteGeometry?.geometry!,
        parcelsById
      )

      const result = await client.request<{ note: Note }>({
        query: gql`
          mutation INSERT_NOTE($note: Note_insert_input!) {
            note: insert_Note_one(object: $note) {
              id
            }
          }
        `,
        variables: {
          note: {
            ...note,
            parcelId: containingParcelId,
            organizationId: organization?.id,
            Feature: { data: noteGeometry },
          },
        },
      })

      // Insert the associate note media references. Media is already uploaded.
      if (noteMedia && noteMedia.length > 0) {
        await client.request({
          query: gql`
            mutation INSERT_NOTE_MEDIA($noteMedia: [NoteMedia_insert_input!]!) {
              insert_NoteMedia(objects: $noteMedia) {
                returning {
                  noteId
                  mediaId
                }
              }
            }
          `,
          variables: {
            noteMedia: noteMedia?.map((m) => ({
              noteId: result.note.id,
              mediaId: m.mediaId,
            })),
          },
        })
      }

      if (state.notes.createAnother) {
        dispatch(actions.setNoteEditMode('content'))
      } else {
        dispatch(actions.setIsCreatingNote(false))
      }

      dispatch(actions.setLastUsedProjectId(note.projectId))
      dispatch(actions.setLastUsedTemplateId(note.templateId))

      setNoteGeometry(undefined)
      refreshListNotes()
      refreshListProjects()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  const handlEditNoteGeometryCancel = () => {
    setNoteGeometry(undefined)
    dispatch(actions.setNoteEditMode('content'))
  }

  const handleEditNoteGeometryComplete = async () => {
    try {
      if (!editingNote) {
        return
      }

      if (noteGeometry && editingNoteContent) {
        const { id, ...feature } = noteGeometry

        const containingParcelId = getContainingParcelId(
          feature.geometry!,
          parcelsById
        )

        await client.request({
          query: gql`
            mutation EDIT_NOTE_AND_GEOMETRY(
              $noteId: uuid!
              $featureId: uuid!
              $note: Note_set_input!
              $feature: Feature_set_input!
            ) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
              update_Feature_by_pk(
                pk_columns: { id: $featureId }
                _set: $feature
              ) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: { content: editingNoteContent, parcelId: containingParcelId },
            featureId: editingNote.feature.id,
            feature: feature,
          },
        })
      } else if (noteGeometry) {
        const { id, ...feature } = noteGeometry

        const containingParcelId = getContainingParcelId(
          feature.geometry!,
          parcelsById
        )

        await client.request({
          query: gql`
            mutation EDIT_NOTE_AND_GEOMETRY(
              $noteId: uuid!
              $featureId: uuid!
              $note: Note_set_input!
              $feature: Feature_set_input!
            ) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
              update_Feature_by_pk(
                pk_columns: { id: $featureId }
                _set: $feature
              ) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: { parcelId: containingParcelId },
            featureId: editingNote.feature.id,
            feature: feature,
          },
        })
      } else if (editingNoteContent) {
        await client.request({
          query: gql`
            mutation EDIT_NOTE($noteId: uuid!, $note: Note_set_input!) {
              update_Note_by_pk(pk_columns: { id: $noteId }, _set: $note) {
                id
              }
            }
          `,
          variables: {
            noteId: editingNote.id,
            note: { content: editingNoteContent },
          },
        })
      }

      setNoteGeometry(undefined)
      dispatch(actions.setEditingNoteId(undefined))
      refreshListNotes()
      refreshListProjects()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  const handleEditNoteGeometry = (note: Pick<Note, 'content'>) => {
    setEditingNoteContent(note.content)
    dispatch(actions.setNoteEditMode('geometry'))
  }

  const openDeleteNoteDialog = () => {
    dispatch(actions.toggleDeleteNotesDialog(true))
  }

  const handleDeleteNote = async () => {
    try {
      if (!editingNote) {
        return
      }

      await client.request({
        query: gql`
          mutation SOFT_DELETE_NOTE($id: uuid!) {
            update_Note_by_pk(
              pk_columns: { id: $id }
              _set: { deletedAt: "now()" }
            ) {
              id
            }
          }
        `,
        variables: { id: editingNote.id },
      })
      // soft delete media associated with note
      await client.request({
        query: gql`
          mutation SOFT_DELETE_MEDIA($noteId: uuid!) {
            update_Media(
              where: { NoteMedia: { noteId: { _eq: $noteId } } }
              _set: { deletedAt: "now()" }
            ) {
              returning {
                id
                NoteMedia {
                  noteId
                  mediaId
                }
              }
            }
          }
        `,
        variables: { noteId: editingNote.id },
      })

      handleDeleteNoteCancel()
    } catch (e) {
      console.error(e)
      dispatch(
        showFeedback({
          message: i18n.t(keys.notes.failedToSave),
          severity: 'error',
        })
      )
    }
  }

  const handleDeleteNoteCancel = () => {
    setNoteGeometry(undefined)
    dispatch(actions.setEditingNoteId(undefined))
    refreshListNotes()
    refreshListProjects()
    dispatch(actions.toggleDeleteNotesDialog(false))
  }

  return (
    <>
      <FitSelectedNote />

      <NoteForm
        key="notes_add"
        onCreateNote={handleNewNoteCreate}
        onCancel={handleNewNoteCancel}
        open={noteEditMode === 'content' && isCreatingNote}
      />

      <NoteForm
        key="notes_edit"
        onEditNote={handlEditNote}
        onCancel={handlEditNoteCancel}
        onEditLocation={handleEditNoteGeometry}
        open={noteEditMode === 'content' && !!editingNote}
        initialNote={editingNote}
        onDelete={openDeleteNoteDialog}
        mode="edit"
      />

      <NoteGeometryDialog
        open={noteEditMode === 'geometry' && !!editingNote}
        note={editingNote}
        onNoteChange={setNoteGeometry}
        onCancel={handlEditNoteGeometryCancel}
        onSave={handleEditNoteGeometryComplete}
        mode="edit"
      />

      <NoteGeometryDialog
        open={noteEditMode === 'geometry' && isCreatingNote}
        onNoteChange={setNoteGeometry}
        onCancel={handleNewNoteCancel}
        note={pendingNote}
        onSave={() => handleSaveNote()}
      />

      <ConfirmationModal
        open={deleteNoteDialog}
        onConfirm={handleDeleteNote}
        onCancel={handleDeleteNoteCancel}
        title={i18n.t(keys.notes.notesPopup.deleteConfirmTitle)}
        message={i18n.t(keys.notes.notesPopup.deleteConfirmMessage)}
        messageStyle={{ fontWeight: 'normal', maxWidth: '432px' }}
        dangerMode={true}
      />
    </>
  )
}
