import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import * as React from 'react'
import { PureComponent } from 'react'
import * as actions from '../app/actions'
import i18n, { keys } from '../i18n'
import { connect } from '../redux/connect'
import { LOCAL_STORAGE_VV_PREFERENCES } from '../redux/preferences/redux'
import { AppDispatchProps, RootStore } from '../redux/types'
import vvapi from '../vvapi'
import PreferredLanguage from './PreferredLanguage'
import PreferredStatistics from './PreferredStatistics'
import PreferredTheme from './PreferredTheme'
import PreferredUnits from './PreferredUnits'

class PreferencesDialog extends PureComponent<ReduxProps & AppDispatchProps> {
  render() {
    return (
      <Dialog
        open={this.props.isPreferencesDialogOpen}
        scroll="paper"
        onClose={this.handleCloseClick}
      >
        <DialogTitle>{i18n.t(keys.preferences)}</DialogTitle>
        <DialogContent>
          <PreferredTheme />
          <PreferredLanguage />
          <PreferredUnits />
          <PreferredStatistics />
          <Divider />
          <List>
            <ListItem button onClick={this.handleClearClick}>
              <ListItemIcon>
                <Icon>delete</Icon>
              </ListItemIcon>
              <ListItemText
                primary={i18n.t(keys.clearSettings)}
                secondary={i18n.t(keys.clearSettingsDescription)}
              />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCloseClick}>
            {i18n.t(keys.generic.close)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  handleClearClick = async () => {
    try {
      await vvapi.settings.update({})
    } catch (exception) {
      // tslint:disable-next-line: no-console
      console.error('error saving empty state', exception)
    }

    localStorage.removeItem(LOCAL_STORAGE_VV_PREFERENCES)

    window.location.reload()
  }

  handleCloseClick = () => {
    this.props.dispatch(actions.toggleSettingDialog(null))
  }
}

const mapState = (state: RootStore) => ({
  isPreferencesDialogOpen: state.app.isPreferencesDialogOpen,
})

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  PreferencesDialog
)
