import * as React from 'react'
import { PureComponent } from 'react'

import { Button, CircularProgress, Grid, Icon, Typography } from '@mui/material'
import { WithTheme, withTheme } from '@mui/styles'
import i18n, { keys } from '../i18n'
import getPositionErrorMessage from '../map/controls/getPositionErrorMessage'
import { MapboxGL } from '../map/MapboxGL'
import withMap, { WithMap } from '../map/withMap'
import * as actions from '../postgis/actions'
import { connect } from '../redux/connect'
import { AppDispatchProps, RootStore } from '../redux/types'

interface State {
  isSearching: boolean
  errorMessage?: string
}

class JumpToCurrentPositionButton extends PureComponent<
  WithTheme & WithMap & ReduxProps & AppDispatchProps,
  State
> {
  state: State = {
    isSearching: false,
  }

  componentDidUpdate(prevProps: this['props']) {
    if (
      prevProps.positionError !== this.props.positionError &&
      this.props.positionError
    ) {
      this.setState({
        isSearching: false,
        errorMessage: getPositionErrorMessage(this.props.positionError),
      })
    }
    if (prevProps.position !== this.props.position && this.props.position) {
      if (this.state.isSearching) {
        const { position } = this.props
        const center: MapboxGL.LngLatLike = [
          position.coords.longitude,
          position.coords.latitude,
        ]
        this.props.map.easeTo({ center })
        this.setState({ isSearching: false })
      }
    }
  }

  render() {
    const { isSearching, errorMessage } = this.state

    return (
      <Grid item xs={12} container>
        <Button
          variant="contained"
          fullWidth
          onClick={this.toggleIsSearching}
          sx={{
            paddingLeft: '0px',
            color: this.props.theme.palette.text.primary,
          }}
        >
          {isSearching ? (
            <CircularProgress size={24} />
          ) : (
            <>
              <Icon sx={{ pr: 4 }}>gps_fixed</Icon>
              <Typography sx={{ color: this.props.theme.palette.text.primary }}>
                {i18n.t(keys.notes.notesPopup.jumpToCurrentLocation)}
              </Typography>
            </>
          )}
        </Button>

        {!!errorMessage && (
          <Typography color="error" variant="inherit">
            {errorMessage}
          </Typography>
        )}
      </Grid>
    )
  }

  toggleIsSearching = () => {
    this.props.dispatch(
      actions.setIsPollingUserPosition(!this.state.isSearching)
    )
    this.setState({
      isSearching: !this.state.isSearching,
      errorMessage: undefined,
    })
  }
}

const mapState = (state: RootStore) => ({
  position: state.postgis.userPosition,
  positionError: state.postgis.userPositionError,
  isGPSOffsetMarkerVisible: state.postgis.isGPSOffsetMarkerVisible,
})
type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withTheme(withMap(JumpToCurrentPositionButton))
)
