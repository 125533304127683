import { FormControl } from '@mui/base'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Stack,
  TextField,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import * as React from 'react'
import i18n, { keys } from '../../i18n'

interface Props {
  open: boolean
  onCancel?: () => void
  onConfirm: (value: string) => void
  title: string | React.ReactNode
  message: string | React.ReactNode
  defaultValue?: string
  characterLimit?: number
}

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
`

const Description = styled(Typography)`
  font-size: 16px;
  font-weight: normal;
  line-height: 19.2px;
  letter-spacing: -0.035em;
  text-align: left;
`

export const InputModal = ({
  open,
  onCancel,
  onConfirm,
  title,
  message,
  defaultValue = '',
  characterLimit,
}: Props) => {
  const [textValue, setTextValue] = React.useState(defaultValue)

  React.useEffect(() => {
    setTextValue(defaultValue)
  }, [defaultValue])

  const theme = useTheme()
  return (
    <Dialog open={open}>
      <DialogTitle>
        {typeof title === 'string' ? <Title>{title}</Title> : title}
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Stack direction="column" gap={2}>
          {typeof message === 'string' ? (
            <Description>{message}</Description>
          ) : (
            message
          )}
          <FormControl
            error={
              characterLimit
                ? (defaultValue?.length ?? 0) > characterLimit
                : undefined
            }
          >
            <TextField
              sx={{
                [`& .MuiInputBase-input`]: {
                  px: 1,
                  py: 1.5,
                },
                [`& .MuiOutlinedInput-notchedOutline`]: {
                  backgroundColor: '#00000033',
                },
                [`&:not(.Mui-focused,:hover)`]: {
                  [`& .MuiOutlinedInput-notchedOutline`]: {
                    border: 'none',
                  },
                },
              }}
              variant="outlined"
              fullWidth
              value={textValue}
              error={
                characterLimit
                  ? (defaultValue?.length ?? 0) > characterLimit
                  : undefined
              }
              onChange={(ev) => setTextValue(ev.target.value)}
            />
            {characterLimit &&
              (defaultValue?.length > characterLimit ||
                defaultValue?.length === 0) && (
                <FormHelperText>{`${
                  defaultValue?.length ?? '-'
                }/${characterLimit}`}</FormHelperText>
              )}
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ color: theme.palette.text.primary }}
          onClick={onCancel}
        >
          {i18n.t(keys.generic.cancel)}
        </Button>
        <Button color={'primary'} onClick={() => onConfirm(textValue)}>
          {i18n.t(keys.generic.confirm)}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
