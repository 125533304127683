import { Reducer } from 'redux'
import { applyNamespace } from 'redux-ts-helpers'
import createAction from 'redux-ts-helpers/lib/createAction'
import { NotesFilterInfo, NotesState } from './types'

export const initialState: NotesState = {
  isCreatingNote: false,
  editingNoteId: undefined,
  filterInfo: {
    searchTerm: '',
    filterNotesByMapView: false,
  },
  lastUsedTemplateId: undefined,
  lastUsedProjectId: undefined,
  selectedProjectIds: [],
  createAnother: false,
  deleteNoteDialog: false,
}

export const constants = {
  ...applyNamespace('notes', {
    setShowNotes: 0,
    setIsCreatingNote: 0,
    setNoteEditMode: 0,
    setEditingNoteId: 0,
    setFocusedNoteId: 0,
    setFilterInfo: 0,
    reset: 0,
    setCreateAnother: 0,
    setSelectedProjectIds: 0,
    setLastUsedProjectId: 0,
    setLastUsedTemplateId: 0,
    toggleDeleteNoteDialog: 0,
  }),
}

export const actions = {
  setLastUsedProjectId: createAction<string | undefined>(
    constants.setLastUsedProjectId
  ),
  setLastUsedTemplateId: createAction<string | undefined>(
    constants.setLastUsedTemplateId
  ),
  setCreateAnother: createAction<boolean>(constants.setCreateAnother),
  setIsCreatingNote: createAction<boolean>(constants.setIsCreatingNote),
  setEditingNoteId: createAction<
    | undefined
    | {
        noteId?: string
        zoomToNote?: boolean
      }
  >(constants.setEditingNoteId),
  setFocusedNoteId: createAction<
    | undefined
    | {
        noteId?: string
      }
  >(constants.setFocusedNoteId),
  setFilterInfo: createAction<NotesFilterInfo>(constants.setFilterInfo),
  reset: createAction(constants.reset),
  setNoteEditMode: createAction<'content' | 'geometry' | undefined>(
    constants.setNoteEditMode
  ),
  setSelectedProjectIds: createAction<string[]>(
    constants.setSelectedProjectIds
  ),
  toggleDeleteNotesDialog: createAction<boolean>(
    constants.toggleDeleteNoteDialog
  ),
}

export const reducer: Reducer<NotesState> = (state = initialState, action) => {
  switch (action.type) {
    case `${constants.setNoteEditMode}`: {
      const noteEditMode: 'content' | 'geometry' | undefined = action.payload

      return {
        ...state,
        noteEditMode,
      }
    }
    case `${constants.setIsCreatingNote}`: {
      const isCreatingNote: boolean = action.payload

      return {
        ...state,
        isCreatingNote,
        noteEditMode: isCreatingNote ? 'content' : undefined,
        editingNoteId: undefined,
      }
    }
    case `${constants.setCreateAnother}`: {
      const createAnother: boolean = action.payload

      return {
        ...state,
        createAnother,
      }
    }

    case `${constants.setFocusedNoteId}`: {
      const { noteId } = action.payload

      return {
        ...state,
        focusedNoteId: noteId,
      }
    }
    case `${constants.setEditingNoteId}`: {
      if (!action.payload) {
        return {
          ...state,
          editingNoteId: undefined,
          focusedNoteId: undefined,
          isCreatingNote: false,
          noteEditMode: undefined,
        }
      }

      const { noteId, zoomToNote } = action.payload

      let fitEditingTimestamp = state.fitEditingTimestamp
      if (zoomToNote) {
        fitEditingTimestamp = Date.now()
      }

      return {
        ...state,
        fitEditingTimestamp,
        editingNoteId: noteId,
        focusedNoteId: noteId,
        isCreatingNote: false,
        noteEditMode: 'content',
      }
    }

    case `${constants.setFilterInfo}`: {
      return {
        ...state,
        filterInfo: action.payload,
      }
    }

    case `${constants.reset}`: {
      return initialState
    }

    case `${constants.setSelectedProjectIds}`: {
      return {
        ...state,
        selectedProjectIds: action.payload,
      }
    }
    case `${constants.setLastUsedTemplateId}`: {
      return {
        ...state,
        lastUsedTemplateId: action.payload,
      }
    }
    case `${constants.setLastUsedProjectId}`: {
      return {
        ...state,
        lastUsedProjectId: action.payload,
      }
    }

    case `${constants.toggleDeleteNoteDialog}`: {
      const deleteNoteDialog: boolean = action.payload
      return {
        ...state,
        deleteNoteDialog,
      }
    }
  }

  return state
}
