import { Translations } from './keys'

export const en: Translations = {
  notificationsTitle: 'Notifications',
  notificationsManage: 'Manage Email Notifications',
  notifications: {
    'order|confirmation': {
      subscribeText: 'Subscribe to order confirmation emails.',
      subscriptionDescription:
        'These emails will be sent out after we have processed your order.',
    },
    'target-delivery|flight-notice': {
      subscribeText: 'Subscribe to flight notice emails.',
      subscriptionDescription:
        'These emails will be sent out two weeks prior to the week of a scheduled flight.',
    },
    'delivery|flight-complete': {
      subscribeText: 'Subscribe to flight complete emails.',
      subscriptionDescription:
        'These emails will be sent out after a flight is completed, letting you know your data will be available in the coming days.',
    },
    'delivery|data-available': {
      subscribeText: 'Subscribe to data available emails.',
      subscriptionDescription:
        'These emails will be sent out when you can view the data in the app.',
    },
  },

  theme: { light: 'Light', dark: 'Dark', themePicker: 'Choose Theme' },
  selectDate: 'Select Date',
  forSelectedParcels: 'For Selected Fields',
  forOtherParcels: 'For Other Fields',
  productsText: 'Products',
  beta: 'Beta',
  betaMessage:
    'Please note you are now working in the beta version of the Vineview app',
  errors: {
    somethingWentWrong: 'Something went wrong',
    tapToReload: 'Tap to reload',
    reportAnIssue: 'Report an Issue',
  },

  user: {
    user: 'User',
    firstName: 'First Name',
    lastName: 'Last Name',
    password: 'Password',
    organization: 'Team',
    confirmPassword: 'Confirm Password',
    email: 'Email',
    switchOrganization: 'Switch Teams',
    role: 'Role',
  },

  focusedBlockDialog: {
    fieldInformation: 'Field Information',
    name: 'Name',
    area: 'Area',
    varietals: 'Varietal',
    rootstock: 'Rootstock',
    trellisType: 'Trellis Type',
    EVImean: 'EVI mean',
    EVIstdev: 'EVI stdev',
    canopyArea: 'Canopy Area',
    CWCmean: 'CWC mean',
    undetectedVines: 'Undetected Vines',
    totalVines: 'Total Vines',
    rowSpacing: 'Row Spacing',
    vineSpacing: 'Vine Spacing',
  },
  soilLayer: {
    soilInformation: 'Soil Information',
    layerToggle: 'Soil Layer',
  },
  elevationLayer: {
    layerToggle: 'Elevation Layer',
  },

  forms: {
    notNull: 'Required',
    confirmation: '{{field}} should match {{otherField}}',
    isUnique: 'Already in use',
    login: {
      login: 'Login',
      loginError: 'Error while logging in',
      passwordRequired: 'Please enter your password',
      emailRequired: 'Please enter your email address',
      notChromeWarning:
        'NOTE: VineView is optimized for Chrome-based browsers. If you exprience issues try using Chrome-based browser instead.',
      emailOrPasswordIncorrect:
        'Login Failed. Your username and/or password do not match.',
    },
    password: {
      lowercase: 'Password must have at least one lower case letter',
      uppercase: 'Password must have at least one upper case letter',
      number: 'Password must have at least one number',
      symbol: 'Password must have at least one symbol',
      length: 'Password must be at least 6 characters',
    },
    resetPassword: {
      title: 'Reset Password',
      submit: 'Reset My Password',
    },
    forgotPassword: {
      title: 'Forgot Password',
      submit: 'Send password reset email',
      forgotMessage: `Enter your email address, and we will email you
        a link with instructions on how to reset your password.`,
      requestedMessage: `Check your email for a link to reset
        your password.  If you do not see it immediately,
        wait a few minutes, or double-check that it didn't
        get into your spam folder.`,
    },
    invalidToken: 'Invalid Token',
    done: 'Done',
    parcelFilter: {
      advancedFilters: 'Advanced Filters',
      deliveriesFor: 'Filter by location: ',
      selectDelivery: 'Filter by date:',
      filter: 'Filter',
      search: 'Filter by name',
      varietal: 'Varietal Name',
      fromDate: 'From Date',
      toDate: 'To Date',
      noDate: 'None',
      today: 'Today',
      sort: 'Sort Groups By',
      sortAlpha: 'Alphabetical',
      sortTime: 'Time of data capture',
      resetSelection: 'Reset Selection',
      rootstock: 'Rootstock',
      trellisType: 'Trellis Type',
      noVarietals: 'No Varietal Info',
      noRootstocks: 'No Rootstock Info',
      noTrellises: 'No Trellis Info',
    },
  },
  colorScheme: {
    chooseColorSchemeButton: 'Choose Colors',
    chooseColorScheme: 'Choose Color Profile',
    revertToDefaults: 'Revert to Default Color Profiles',
    redYellowGreen: 'Red Yellow Green',
    recommended: 'VineView recommended',
    recommendedEVI: 'VineView recommended EVI',
    allBlues: 'All Blues',
    redYellowBlue: 'Red Yellow Blue',
    cyanToRed: 'Cyan to Red',
    showHealthyAsGreen: 'Show Healthy As Green',
    showSymptomaticAsRed: 'Show Symptomatic As Red',
    showPresentAsGreen: 'Show Present As Green',
    relativeThermal: 'Relative Thermal',
    missingVines: 'Missing Vines',
    pureVineDefault: 'PureVine Default',
    ceviDefault: 'CEVI Default',
    legacy: 'Legacy',
    redleafDefault: 'Redleaf Default',
  },
  legend: {
    selectActiveLayerMenuTitle: 'Map Data',
    changeActiveProductTooltip: 'Select map data',
    changeColorSchemeTooltip: 'Change color profiles',
    scaleSwitcherTooltip:
      'Switch between absolute and relative colors profiles',
    isolateColorTooltip: 'Tap a color to isolate it on the map.',

    filterTooltip: 'Data Filter Options',
    clearFilter: 'Clear Filter',
    colorLegend: 'Legend',
    noLegend: 'No Legend',
    noDataSelected: 'No Data Selected',
    scale: {
      absolute: 'absolute',
      relative: 'relative',
    },
  },
  logout: 'Logout',
  logoutConfirm: 'Are you sure you want to log out?',
  serverError: 'Server Error',
  cursorLon: 'lng: {{lng}}',
  cursorLat: 'lat: {{lat}}',
  generic: {
    any: 'Any',
    alertConst: 'Alert',
    back: 'Back',
    cancel: 'Cancel',
    clear: 'Clear',
    close: 'Close',
    confirm: 'Confirm',
    continue: 'Continue',
    copied: 'Copied!',
    copy: 'Copy',
    create: 'Create',
    created: 'Created',
    csv: 'CSV',
    shapefile: 'Shapefile',
    geojson: 'GeoJSON',
    delete: 'Delete',
    duplicate: 'Duplicate',
    download: 'Download',
    downloadFormat: 'Download Format',
    edit: 'Edit',
    expires: 'Expires',
    export: 'Export',
    help: 'Help',
    high: 'High',
    loading: 'Loading',
    low: 'Low',
    na: 'N/A',
    next: 'Next',
    no: 'no',
    noThanks: 'no thanks',
    noData: 'No Data Available',
    none: 'None',
    ok: 'Ok',
    refresh: 'Refresh',
    remove: 'Remove',
    reset: 'Reset',
    retry: 'Retry',
    save: 'Save',
    selectAll: 'Select All',
    update: 'Update',
    view: 'View',
    xlsx: 'XLSX',
    pdf: 'PDF',
    yes: 'yes',
    unavailable: 'Unavailable',
    name: 'Name',
    observations: 'Observations',
    average: 'Average',
    successLevels: {
      none: '-',
      low: 'Poor',
      fair: 'Fair',
      good: 'Good',
      excellent: 'Excellent',
    },
    learnMore: 'Learn More',
    noItems: 'No items to display',
    on: 'On',
    off: 'Off',
    move: 'Move',
  },
  attachments: {
    attachments: 'Attachments',
    chooseFile: 'Choose a File',
    fileLimitWarning: 'You can only upload up to 10 files',
  },
  gis: {
    area: 'Area',
    zone: 'Zone',
    canopyArea: 'CA',
    undetectedVine: 'Undetected Vine',
  },
  toggleStats: 'Toggle Stats for {{product}}',
  products: {
    leafroll_high: 'Highly Symptomatic',
    leafroll_low: 'No Symptoms',
  },
  productNames: {
    GRAY_SCALE: 'Grayscale',
    TRUE_COLOR: 'True Color',
    CIR: 'Color Infrared',
    EVI_LEGACY: 'Calibrated EVI - Legacy (Absolute)',
    EVI_LEGACY_RELATIVE: 'Calibrated EVI - Legacy (Relative)',
    RELATIVE_THERMAL_RASTER: 'Relative Thermal',

    CANOPY_GAP: 'Canopy Gaps',

    VIGOR_ZONE: 'Calibrated Vigor Zones',
    PURE_VIGOR_ZONE: 'PureVine™ Zones',
    RELATIVE_THERMAL_ZONE: 'Relative Thermal Zones',

    EVI_POINT: 'PureVine™',
    WATER_INDEX: 'Canopy Water Content',
    LEAFROLL: 'PureVine Leafroll',
    LEAFROLL_RASTER: 'Leafroll Image',
    MISSING_VINE: 'Missing Vines',
    FD: '',

    RED_LEAF: 'PureVine RedLeaf',
    RED_LEAF_RASTER: 'RedLeaf Image',
  },
  productDescriptions: {
    EVI_POINT: `Enhanced Vegetation Index (EVI) layer presented on a
    vine-by-vine basis. All cover crop and soil data has been removed for
    canopy-only measurements.`,
    VIGOR_ZONE: `Zones generated to highlight general vigor trends across the
    field. Soil data has been removed from the calculations`,
    PURE_VIGOR_ZONE: `Zones generated to highlight vigor trends across the
    field. All soil and cover crop data has been removed from the
    calculations.`,
    EVI_LEGACY_RELATIVE: `Enhanced Vegetation Index layer highlighting general
    vigor trends, on a relative scale`,
    EVI_LEGACY: `Enhanced Vegetation Index layer highlighting general
    vigor trends, on an absolute scale`,
    CANOPY_GAP:
      'Measurements of canopy area at 50cm intervals along every vine row.',
    TRUE_COLOR: `High resolution aerial image of targeted vineyard blocks.
    Presented in an enhanced true color format`,
    CIR: `High resolution aerial image of targeted vineyard blocks.
    Presented in a color infrared format`,

    GRAY_SCALE: 'Grayscale',
    LEAFROLL: 'Leafroll',
    LEAFROLL_RASTER: 'Leafroll Raster',
    WATER_INDEX:
      'Canopy Water Content (CWC) measurements visualized on a vine-by-vine basis.',
    TEMPERATURE_ZONE:
      'Zones generated to highlight temperature trends across the field',
    RELATIVE_THERMAL_ZONE: `Zones generated to highlight relative temperature
    trends across the field`,
    RELATIVE_THERMAL_RASTER: `High resolution thermal aerial image of
    targeted vineyard blocks. Presented in a relative format to highlight
    temperature patterns`,
    MISSING_VINE: `An accurate classification of missing, dead, and present
    vines at exact vine locations.`,
    FD: '',

    RED_LEAF: `RedLeaf values visualized in a vine-by-vine format.
      For each vine, two key statistics are measured: the mean redness
      of detected red leaves, and the percentage of the canopy
      containing red leaves.`,
    RED_LEAF_RASTER: `Visual representation of where canopy has been detected
      to be more red than green. It is intended to reveal where
      in the canopy red leaves are located, which can provide
      additional insight.`,
  },
  valueNames: {
    GRAY_SCALE: '',
    TRUE_COLOR: '',
    CIR: 'Color Infrared',
    EVI: 'Enhanced Vegetation Index',
    Thermal: 'Relative Thermal',
    CanopyGap: 'Canopy Gap',
    CWC: 'Canopy Water Content',
    LR_Score: 'Leafroll Probability',
    MV_Class: 'Missing Vine',
    FD: '',

    RedMean: 'Redleaf Index',
    PercentRed: 'Coverage',
    Area: 'Area',
    PercentLR: 'Coverage',
    RowID: 'VineView Row',
    PlantID: 'Plant ID',
  },
  valueAcronyms: {
    GRAY_SCALE: '',
    TRUE_COLOR: '',
    CIR: 'CIR',
    EVI: 'EVI',
    Thermal: '',

    CanopyGap: 'CG',

    CWC: 'CWC',
    LR_Score: 'LR',
    MV_Class: 'MV',
    FD: 'FD',

    RedMean: 'RLI',
    PercentRed: '',
    PercentLR: 'LR',
  },
  map: {
    map: 'Map',
    selectAField: 'Select a Field',
    allParcels: 'all {{totalParcels}} fields',
    someParcels: '{{selectedParcels}} of {{totalParcels}} fields',
    resetBearing: 'Reset View',
    mapSettings: 'Map Settings',
  },
  customLayers: {
    editLayers: 'Edit Layers',
  },
  parcelDrawer: {
    ungrouped: 'Ungrouped',
    unknownList: 'Unknown',
    mostRecentDate: 'Date of most recent data',
    noDataForCurrentDate: 'No data available for current date',
    selectParcels: 'Select Fields',
    noResults: 'No results',
    unavailableForSelectedDate: 'Unavailable For Selected Date',
  },
  notes: {
    failedToSave: 'Failed to save note',
    failedToLoad: 'Failed to load notes',
    failedToDelete: 'Failed to delete note',
    showOnMap: 'Show on Map',
    pinColor: 'Pin Color',
    filter: {
      search: 'Search Notes',
      fromDate: 'From Date',
      toDate: 'To Date',
      allTemplates: 'All Templates',
      allProjects: 'All Projects',
    },
    general: {
      placeholder: 'Add a comment...',
    },
    editLocationSnackbar: 'Edit the shape. Then click',
    noteSaved: 'Note Saved',
    noteDelete: 'Note moved to archived.',
    undo: 'Undo',
    notesPopup: {
      save: 'Save',
      note: 'Note',
      location: {
        location: 'Location',
        currentLocation: 'Current Location',
        pointOnMap: 'Point On Map',
        areaOnMap: 'Area On Map',
      },
      deleteConfirmTitle: 'Delete Note',
      deleteConfirmMessage: 'Are you sure you want to delete this Note?',
      saveLocation: 'Save location',
      updateLocation: 'Update Location Now',
      noteType: 'Note Type',
      createAnother: 'Create Another',
      editLocation: 'Edit Location',
      currentLocationAgo: {
        unavailable: 'Unavailable',
        lastUpdated: 'Last updated',
      },
      newNote: 'New Note',
      editNoteType: 'Edit {{noteType}} Note',
      selectLocation: 'Select Location',
      pointInstruction:
        'Drag the map to choose a new location or use the button below to jump to your current location.',
      polygonInstruction:
        'Click the map to place points. Click an existing point ' +
        'to complete the polygon. Double-click a point to edit the shape.',
      jumpToCurrentLocation: 'Jump to current location',
    },
    createdBy: 'Created by: {{firstName}} {{lastName}}',
    updatedBy: 'Updated by: {{firstName}} {{lastName}}',
    export: 'Export',
    fileName: 'File Name',
    fileNameValidation: 'Please enter valid file name',
    exportBoxTitle: 'Export Notes',
    noNotes: 'No notes available in project',
    downloadImagesTitle: 'Download Images',
    downloadImagesDescription: 'Are you sure you want to download',
    deleteImageTitle: 'Delete Image',
    deleteImageDescription: 'Are you sure you want to delete 1 file?',
    deleteImagesTitle: 'Delete Images',
    deleteImagesDescription: 'Are you sure you want to delete',
    file: 'file',
    files: 'files',
    Files: 'Files',
    projects: 'Projects',
  },
  layerDrawer: {
    fieldBoundaries: 'Field Boundaries',
    fieldLabels: 'Field Labels',
    notes: 'Show Notes',
    toggleScaleBar: 'Scale Bar',
    toggleCursorLocation: 'Cursor Location',
    backgroundRaster: 'Background Map View',
    numberOfGroups: 'Groups',
    value: 'value ranges',
    area: 'equal areas',
    downloadDialog: {
      title: 'Download Map',
      description: 'Choose a download option',
      downloadPNG: 'Download as PNG',
      printMap: 'Print',
      exportMapHeading: 'Export Map',
    },
  },
  userPosition: {
    mapToolbarTooltip: 'Follow My Position',
    error: {
      missingPermission: 'Missing permissions to access position',
      positionNotAvailable: 'Your position is not available',
      unknown: 'An error occurred fetching your position',
    },
  },
  preferences: 'Preferences',
  clearSettings: 'Clear Settings',
  clearSettingsDescription: 'This resets all settings to their defaults.',
  darkMode: 'Dark Mode',
  fieldsTab: 'Fields',
  rateMapTab: 'Rate Map Configuration',
  notesTab: 'Notes',

  colorsTab: 'Colors',
  layersTab: 'Layers',
  settingsTab: 'Settings',
  zoomToSelection: 'Zoom To Selection',
  zoomToPosition: 'Zoom To My Location',
  demo: 'Demo',
  noteForm: {
    failedToLoad: 'Failed to load templates',
    template: 'Note Template',
    templateTitle: 'Template',
    reservedNames: {
      pinpoint: {
        title: 'PinPoint Button Capture',
        button: 'Button',
        double: 'Double Press',
        horizontalAccuracy: 'Horizontal Accuracy',
        numSV: 'Number of Satellites',
        altitude: 'Altitude',
        hmsl: 'Height Above Mean Sea Level',
        fixType: 'Fix Type',
        date: 'Date/Time',
        label: 'Label',
      },
      note: 'Note',
      __VV__GENERAL__TEMPLATE__: 'General',
      __VV_PINPOINT_CAPTURE__: 'PinPoint Button Capture',
      __VV__VINEYARD_PRODUCTION_INSPECTION__: 'Vineyard Production Inspection',
      __VV__PRODUCTION_STATUS__: 'Production Status',
      __VV__PRODUCING__: 'Producing',
      __VV__NONPRODUCING__: 'Non-Producing',
      __VV__ROOTSTOCK__: 'Rootstock',
      __VV__MISSING__: 'Missing',
      __VV__NA__: 'N/A',
      __VV__IRRIGATION_SUPPLY__: 'Irrigation Supply',
      __VV__PRIMARY__: 'Primary',
      __VV__SECONDARY__: 'Secondary',
      __VV_NONE__: 'None',
      __VV__VIRUS_STATUS__: 'Virus Status',
      __VV__POWDERY_MILDEW__: 'Powdery Mildew',
      __VV__DOWNY_MILDEW__: 'Downy Mildew',
      __VV__BOTRYTIS__: 'Botrytis',
      __VV__PIERCES__: `Pierce's`,
      __VV__ESCA__: 'Esca',
      __VV__LEAFROLL__: 'Leafroll',
      __VV__RED_BLOTCH__: 'Red Blotch',
      __VV__DISEASE_INSPECITON__: 'Disease Inspection',
      __VV__CLUSTER_COUNT__: 'Cluster Count',
      __VV__SHATTER_PRESENCE__: 'Shatter Presence',
      __VV__CLUSTERS_INFECTED__: 'Clusters Infected',
      __VV__DISEASE_OBSERVED__: 'Disease Observed',
      __VV__SAMPLE_TAKEN__: 'Sample Taken',
      __VV__CANOPY_SYMPTOMATIC__: 'Canopy Symptomatic',
      __VV__SHATTER__: 'Shatter',
      __VV_PERCENTAGE__: 'Percentage',
      __VV_BLACK_ROT__: 'Black Rot',
      __VV_LEAF_SPOT__: 'Leaf Spot',
      __VV_CLUSTER_WEIGHT__: 'Cluster Weight',
    },
    errors: {
      errorDeleting: 'Failed to delete template',
      errorSaving: 'Failed to save template',
      templateHasErrors:
        'Template contains errors. Please review for any issues',
      atLeastOneField: 'Template must have at least one field',
      mustDefineOptions: 'Template must define options',
      fieldTitleRequired: 'Field title is required',
      fieldTitleMustBeUnique: 'Field title must be unique',
      formTitleRequired: 'Template title is required',
      duplicateFormName: 'A Template with this name already exists',
    },
    fieldTypes: {
      text: 'Text',
      number: 'Number',
      dropdown: 'Dropdown',
      checkbox: 'Checkbox',
      checkboxes: 'Checkboxes',
    },
    manage: {
      title: 'Templates',
      info: `Here you can create custom templates. These templates can
    be used to create new types of notes or samples.`,
      templateNameFilter: 'Template Name...',
      columns: {
        templateName: 'Template Name',
        createdDate: 'Created Date',
        createdBy: 'Created By',
      },
      newTemplate: 'New Template',
      dialogs: {
        delete: {
          title: 'Delete Template',
          message: 'Are you sure you want to delete this template?',
        },
      },
    },

    addOption: 'Add Option',
    titlePlaceholder: 'Title...',
    newTemplate: 'New Template',
    addField: 'Add New Field',
    addNew: 'Add New',
    toggleAdmin: 'Templates',
    formName: 'Form Name',
    propertyName: 'Property Name',
    propertyType: 'Property Type',
    propertyEnum: 'Valid Options',
    propertyEnumOptions: 'Please enter options as comma separated values.',
    newForm: 'New Form',
    newProperty: 'New Data',
    showAsMenu: 'Show as menu',
    addProperty: 'Add Data',
    deleteProperty: 'Remove Data',
    cantDeleteInUse: 'This form is currently in use and cannot be deleted.',
    note: 'Note',
    date: 'Date',
    text: 'Text',
    number: 'Number',
    truefalse: 'Checkbox',
    choices: 'Choices',
    formNameError: 'Please enter form name properly.',
    propertyNameError: 'Please enter property name properly.',
    formAndPropertyError: 'Please enter form and property name properly.',
    createNote: 'Create a Note',
  },
  preview: 'Preview',
  areYouSure: 'Are you sure?',
  confirmDelete: 'Are you sure? This cannot be undone.',
  save: 'Save',
  delete: 'Delete',
  formType: 'Note type',
  dataDownload: {
    buttonText: 'Download Data',
  },
  reports: {
    singular: 'Report',
    plural: 'Reports',
    chooseDataProducts: 'Choose Data Products',
    includeStats: 'Include Statistics (Histogram & Pie Chart)',
    dataProducts: 'Data Products',
    noProducts: 'No Data Products Found',
    reportOptions: 'Report Options',
    includeBlockReports: 'Include individual reports for each block',
    title: '{{orgName}} Metrics Report',
    print: 'Print',
    preparedBy: 'Prepared By VineView',
    preparedByOn: 'Prepared By VineView on',
    flightDate: 'Flight Date',
    whatsInside: `What's inside`,
    client: 'Client',
    ranch: 'Ranch',
    vineSpacing: 'Vine Spacing',
    dataProduct: 'Data Product',
    blockArea: 'Block Area',
    detectedVines: 'Detected Vines',
    undetectedVines: 'Undetected Vines',
    areaNotInUse: 'Area Not In Use',
    rows: 'Rows',
    meanEVI: 'Mean EVI',
    includeRelative: 'Include Relative',
    showBlockBoundaries: 'Show Field Boundaries',
    pages: 'Pages',
    flightInformation: 'Flight Information',
    blockInformation: 'Block {{parcel}} Information',
    groupPage: 'Group Page',
    individualPages: 'Individual Block Pages',
    EVIcov: 'EVI Coefficient of Variation',
    EVIstdev: 'EVI Standard Deviation',
    EVImean: 'EVI Mean',
    PercentGaps: 'Percent Gaps',
  },

  visualization: {
    visualization: 'Visualization',
    primary: 'Primary',
    secondary: 'Secondary',

    absolute: 'Absolute',
    relative: 'Relative',
    threshold: 'Threshold',
    coverage: 'Sensitivity',
    coverageMin: 'Coverage Min',
    coverageMax: 'Coverage Max',

    numberOfGroups: 'Classes',
    groupBy: 'Classify By',
    equalAreas: 'Equal Areas',
    valueRanges: 'Value Ranges',

    opacity: 'Opacity',
    filter: 'Filtered',
    filterProperty: 'Filter Property',
  },

  units: {
    unitSystemPicker: 'Choose Unit System',
    areaPicker: 'Choose Area Unit',
    areaLabel: 'Area',
    unitSystem: {
      imperial: 'Imperial',
      metric: 'Metric',
    },
    area: {
      hectare: 'Hectare',
      acre: 'Acre',
    },
    lengthPicker: 'Choose Length Unit',
    lengthLabel: 'Length',
    length: {
      meter: 'Meter',
      foot: 'Foot',
    },

    perimiterLabel: 'Perimeter',

    plantCountLabel: 'Plants',

    countLabel: 'count',
  },

  language: {
    languagePicker: 'Choose Language',
  },

  statistics: {
    statisticsPicker: 'Choose Default Statistics Format',
    values: 'Values',
    percentages: 'Percentages',
  },

  stats: {
    historic: 'Historical',
    current: 'Current',
    organization: 'Team',
    group: 'Group',
    block: 'Block',
    date: 'Date',
    mean: 'Mean',
    stDev: 'Standard Deviation',
    total: 'Total',
    undetected: 'Undetected',

    coverage: 'Coverage ≤ {{coverage}}%',
    totalWithUndetected: 'Total with Undetected',
    missing: 'Missing',
    present: 'Present',
    download: 'Download',
    headerTitle: '{{productName}} Stats',
    classes: {
      Missing: 'Missing',
      0: 'Missing',
      Present: 'Present',
      1: 'Present',
      Dead: 'Dead',
      2: 'Dead',
    },
    usage: 'Usage',
    usageTooltip: 'Percentage of field with active plants',
    coefficientOfVariation: 'Variation',
    coefficientOfVariationTooltip: 'Relative measure of variability in a field',
    acres: 'Acres',
    hectares: 'Hectares',

    average: 'Average',
    currentSelection: 'Current\nSelection',
    trendTooltip: 'Change in {{stat}} since last capture',
  },
  measure: 'Measure',
  statsZones: 'Zonal Stats',
  drawLine: 'Draw Line',
  drawPolygon: 'Draw Polygon',
  clearAll: 'Clear All',
  download: 'Download',
  openInAvenza: 'Open In Avenza Maps™',
  copyUrl: 'Copy Url to Clipboard',
  copyFailed: 'Automatic Copy Failed',
  copyFailedDetail: 'Use this dialog to select the following text:',
  copySuccess: 'Text copied to clipboard!',
  reprocessDownload: 'Reprocess Download',
  reprocessDownloadAll: 'Reprocess Download All',
  downloadPending: 'Download Pending',
  downloadError: `Download request failed.
  Please contact our sales team`,
  allFiles: 'all files',
  requestDownload: 'Request download',
  requestDownloadAll: 'Request download all',
  confirmRequestDownload: 'Confirm Download Request',
  confirmRequestDownloadMessage:
    'Are you sure you would like to request {{filename}} for download?',
  confirmReprocessDownload: 'Confirm Download Reprocess',
  confirmReprocessDownloadMessage:
    'Are you sure you would like to reprocess {{filename}} for download?',
  downloadRequested: 'Download Requested',
  downloadRequestedCheckBack:
    'Download of {{filename}} requested. Please check back later',
  downloadRequestError:
    'Error Requesting Download. Please try again. If issue persists, please contact our sales team',
  downloadRequestErrorTitle: 'Error Requesting Download',
  dotSize: 'Dot Size',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  extraLarge: 'Extra Large',
  manageOrganization: `Manage Team`,
  removeUser: 'Remove User',
  uninviteUser: 'Revoke invitation',
  resendInvite: 'Resend invitation',
  addNewUser: 'Invite New User',
  add: 'Add',
  confirmUpdateUser: `Are you sure you would like to change this user's role from "{{oldRole}}" to "{{newRole}}?`,
  confirmUpdateUserTitle: 'Confirm Change User Role',
  confirmUpdateUserSuccess: 'User role updated!',
  confirmRemoveUser: 'Are you sure you would like to remove this user?',
  confirmRemoveUserTitle: 'Confirm Remove User',
  confirmRemoveUserSuccess: 'User removed!',
  confirmUpdateSelf: `You're about to remove yourself as an owner of this team. If you continue, you will no longer be able to manage users for this team.`,
  confirmUpdateSelfTitle: 'Confirm Update Self',
  confirmRemoveSelf: `You're about to remove yourself from this team. If you continue, you will no longer be able to manage users for this team.`,
  confirmRemoveSelfTitle: 'Confirm Remove Self',
  manageOrgNotPermitted:
    'You are not permitted to manage this team. If this is incorrect, please contact VineView support.',
  invitedBy: 'Invited By',
  confirmUninviteUser: 'Are you sure you would like to revoke this invitation?',
  confirmUninviteUserTitle: 'Confirm Revoke Invitation',
  confirmUninviteUserSuccess: 'Invitation revoked!',
  resend: 'Resend',
  revoke: 'Revoke',
  confirmInviteResend: 'Are you sure you would like to resend this invitaton?',
  confirmInviteResendTitle: 'Confirm Resend Invitation',
  confirmInviteResendSuccess: 'Invitation Resent!',
  signup: 'Register',
  inviteInvalid: 'This invite is invalid',
  continueWith: 'Continue with:',
  inviteMessage:
    '{{invitee}} has invited you to join the {{organizationName}} team',
  join: 'Accept Invitation',
  joinWithOther: 'Join with another account',

  emailInUse:
    'The email assocciated with this invitation is already in use. If you are the owner of that account, please login to accept this invitation.',
  emailInvalid:
    'Please enter your email address in this format: "yourname@example.com"',
  signupError: 'Error registering',
  inviteConflict: 'Invitation Already Exists',
  inviteForEmailAlreadyExists:
    'An invitation for {{userEmail}} already exists, or a user with this email already belongs to your team.',
  userAlreadyBelongsToOrg: 'You are already a member of {{organizationName}}',
  invitation: 'VineView Invitation',
  joinedOrgSuccessTitle: 'Successfully Joined Team',
  joinedOrgSuccess: `You've joined {{organizationName}}. Would you like to switch to that team now?`,
  accepted: 'Accepted',
  expired: 'Expired',
  wrongInviteEmail: 'Looks like this invitation is for another user.',
  switchAcount: 'Switch accounts',
  toAcceptInvite: 'to accept this invite.',
  mustBeSignedIn: 'This invite cannot be accepted while logged out.',
  signUpToAccept: `Register to accept`,
  invitationPending: 'Invitation Pending',
  invitationExpired: 'Invitation Expired',
  editName: 'Edit Name',
  members: 'Members',
  fields: 'Fields',
  groupDownloadByRequest: 'Group downloads are generated by request.',
  groupDownloadToRequest: 'To request a download click ',
  groupDownloadMayTakeAMinute:
    'It may take several minutes to generate a download once requested.',
  groupDownloadOnceGenerated:
    'Once a file has been generated it can be downloaded by clicking ',
  groupDownloadOnceGeneratedPost: '',
  speedTracker: 'Speed Tracker',
  showSpeedTracker: 'Show Speed Tracker',
  gps: 'GPS',
  showLatLon: 'Show lat/lon',
  prescribedSpeed: 'Prescribed Speed',
  currentSpeed: 'Current Speed',
  soundWhenChangingZones: 'Sound when changing zones',
  GPSLocation: 'GPS Location',
  adjustMapPosition: 'Adjust map position',
  adjustCurrentGPSMapPosition: 'Adjust current GPS map position',
  moveTheMapToSetCorrectLocation:
    'Move the map to set the correct location and press "confirm"',
  offset: 'Offset',
  userLocation: 'User location',
  cursorLocation: 'Cursor location',
  distance: 'Distance',
  geoPdfDownload: 'Download GeoPDF',
  geoPdfDownloadByRequest: 'GeoPDF downloads are generated by request.',
  productGuide: 'Product Guide',
  appGuide: 'App User Guide',
  faq: 'FAQ',
  contactUs: 'Contact Us',
  name: 'Name',
  requestedBy: 'Requested By',
  actions: 'Actions',
  status: 'Status',
  downloads: 'Downloads',
  dateRequested: 'Date Requested',
  deleteDownload: 'Delete Download',
  deleteDownloadConfirm: 'Are you sure you would like to delete {{name}}?',
  filename: 'Filename',
  groupName: 'Group Name',
  flightDate: 'Date',
  blockByBlockAverage: 'Block Avg',
  viewAs: 'View As',
  'manage-subscription': 'Manage Subscription',
  paySubscription: 'Pay Subscription',
  productPriceDescription: 'USD per acre every year',
  noSubscriptions: 'No Subscription exists for this team',
  renewsOn: 'Renews on',
  invoiceNotSent: 'Invoice has not been sent yet. Please check back soon.',
  month: {
    one: 'month',
    other: '{{count}} months',
  },
  year: {
    one: 'year',
    other: '{{count}} years',
  },
  groupDownloadsFilename: 'group-downloads.zip',
  multipleDownloads: '{{num}} downloads',
  rowsPerPage: 'Page Size',
  tableRowsPerPage: 'Rows per page:',
  nextPage: 'Next Page',
  previousPage: 'Previous Page',
  filenames: {
    'all-files': {
      zip: 'all-files.zip',
    },
  },
  filters: 'Filters',
  all: 'All',
  readyForDownload: 'Ready for Download',
  inProgress: 'In Progress',
  complete: 'Complete',
  pending: 'Pending',
  processing: 'Processing',
  error: 'Error',
  unprocessed: 'Unprocessed',
  downloadType: 'Download Type',
  geoPdf: 'GeoPDF',
  rawData: 'Raw Data',
  request: 'Request',
  selectType: 'Select Type',
  groupsAndDates: 'Groups And Dates',
  settings: 'Settings',
  reviewAndRequest: 'Review and Request',
  selectGroupsAndDates: 'Select Groups and Dates',
  groups: 'Groups',
  allGroups: 'All Groups',
  editGroupAccess: 'Edit Group Access',
  dates: 'Dates',
  geoPdfSettings: 'GeoPDF Settings',
  product: 'Product',
  duplicatesFoundTitle: 'Duplicates Found',
  duplicatesFoundDetail:
    "Some downloads requested already exist. Please choose whether you'd like to <b>Skip</b> or <b>Replace</b> the duplicated downloads below.",
  skip: 'Skip',
  replace: 'Replace',
  skipAll: 'Skip All',
  replaceAll: 'Replace All',
  rawDataDownloadSettings: 'Raw Data Download Settings',
  file: 'File',
  noFilesAffectedTitle: 'No Files Affected',
  noFilesAffectedDetail:
    'The selected settings and the group/date/filename combinations that have been skipped resulted in no effect',
  colorProfile: 'Color Profile',
  filesCount: '{{fileCount}} Files',
  numberOfFiles: 'Number of Files',
  expirationDate: 'Expiration Date',
  downloadTableInstruction:
    'Downloads are generated by request. To get started, click “{{request}}”.',
  filterBy: 'Filter by',
  popOutStats: 'Undock stats',
  dockStats: 'Dock stats',
  range: 'Range',
  downloadChartDataTitle: 'Export chart data',
  closeStatWindow: 'Close statistics window',
  weightedAvgWithError: 'Weighted Average with Error',
  historicChartConfig: {
    xAxisLabel: 'X-Axis Label',
    yAxisLabel: 'Y-Axis Label',
    meanLabel: 'Mean Label',
    stdevLabel: 'Standard Deviation Label',
    weightLabel: 'Weight Label',
    mean: 'Mean Property Name',
    stdev: 'Standard Deviation Property Name',
    weight: 'Weight Property Name',
  },
  downloadOutputTypeLabel: 'File Structure',
  downloadMergeGroupsLabel: 'Merged',
  downloadBlockByBlockLabel: 'Parcel-by-parcel',
  downloadBothLabel: 'Both',
  pauseAllJobs: 'Pause all jobs',
  unpauseAllJobs: 'Unpause all jobs',
  mobileAdvertisementTitle: 'On Mobile?',
  mobileAdvertisementText:
    'VineView App now available on App Store & Google Play!',
  samplePlanDashboard: 'Sampling Plans',
  samplePlanNameColumnLabel: 'Name',
  samplePlanDateColumnLabel: 'Creation Date',
  samplePlanFormColumnLabel: 'Form',
  samplePlanBlocksColumnLabel: 'Blocks',
  samplePlanSamplesColumnLabel: 'Samples',
  samplePlanLastUpdateColumnLabel: 'Last Update',
  samplePlanStatusColumnLabel: 'Status',
  samplePlanStatusPendingLabel: 'Pending',
  samplePlanStatusInProgressLabel: 'In Progress',
  samplePlanStatusCompleteLabel: 'Complete',
  samplePlanCreateNewLabel: 'Create New Sampling Plan',
  samplePlanMoreMenuMarkAsCompleteLabel: 'Mark as complete',
  samplePlanBlockIdColumnLabel: 'Block',
  samplePlanBlockLocationColumnLabel: 'Location',
  samplePlanBlockSampleCountColumnLabel: 'Samples',
  samplePlanDownloadPlanLabel: 'Download Plan',
  samplePlanDownloadPlanFormTitle: 'Download Sampling Plan',
  samplePlanDownloadPlanFormDescLabel: 'Block Selection',
  samplePlanNewLabel: 'New Sampling Plan',
  samplePlanNewStep1Label: 'Name',
  samplePlanNewStep2Label: 'Form Template',
  samplePlanNewStep3Label: 'Block Selection',
  samplePlanNewStep4Label: 'Methodology',
  samplePlanNewStep5Label: 'Protocol',
  samplePlanNewStep6Label: 'Confirm',
  samplePlanNewNameLabel: 'Name your Sampling Plan',
  samplePlanNewNameTipLabel: 'Write your Sampling Plan Name',
  samplePlanNewFormsLabel: 'Forms',
  samplePlanNewSelectFormTemplateLabel: 'Select a Form Template',
  samplePlanNewCreateFormTemplateLabel: 'Create New or Edit Existing Templates',
  samplePlanNewProjectInstructionsLabel: 'Instructions for Sampling Collection',
  samplePlanNewSampleTypeLabel: 'Sampling Type',
  samplePlanNewStatisticalMethodLabel: 'Statistical Method',
  samplePlanNewBlockSelectionLabel: '',
  samplePlanNewSameForAllLabel: '',
  samplePlanNewZonesPerBlockLabel: 'Zones per block',
  samplePlanNewZonesPerBlockErrorLabel: 'Must be a number',
  samplePlanNewMarginOfErrorLabel: 'Margin of error',
  samplePlanNewAvgMarginOfErrorLabel: 'Average margin of error',
  samplePlanNewConfidenceLevelLabel: '| Confidence level:',
  samplePlanNewMarginOfErrorTooltip:
    'The larger the margin of error, the less confidence there is in the results reflecting the entire population. Improve it by increasing the sample size or reducing sample variability with more weighted zones.',
  samplePlanNewDivergenceTooltip:
    "Divergence quantifies the difference between the number of samples set by you and the entire block's vine population. Values range from 0 to 1, with lower values indicating better accuracy.",
  samplePlanNewSampleNumberPerBlock: 'Select number of samples per block',
  samplePlanNewSameForAll: 'Same for all',
  samplePlanNewGenerateLabel: 'Please wait. Generating Samples...',
  samplePlanNewFormTemplatePlaceholderLabel: 'Choose a form template...',
  samplePlanNewChooseMethod: 'Choose your Statistical Method',
  samplePlanNewSampleTypePlaceholderLabel: 'Choose a sample type...',
  samplePlanNewSampleFieldPlaceholderLabel: 'Choose a field...',
  samplePlanNewSampleDataSourcePlaceholderLabel: 'Choose a datasource...',
  writeHere: 'Write here...',
  next: 'Next',
  confirm: 'Confirm',
  back: 'Back',
  sampleResultBlockLabel: 'Block',
  sampleResultSamplerLabel: 'Sampler',
  sampleResultCompletionDateLabel: 'Completion Date',
  sampleResultCompletionHourLabel: 'Completion Time',
  samplePlanResultCheckBoxContentTrue: 'Yes',
  samplePlanResultCheckBoxContentFalse: 'No',
  samplePlanDownloadTitleLabel: 'Download Results',
  samplePlanDownloadDescLabel: 'Select what kind of data you want to download:',
  samplePlanDownloadCsv: 'CSV',
  samplePlanDownloadGeoJson: 'Geojson',
  samplePlanReportTitle: 'Sampling Overview',
  samplePlanNoSamplePlans:
    'It looks like you haven’t created a Sampling Plan yet',
  search: 'Search',
  results: 'Results',
  seeResults: 'See Results',
  filterIconTooltip: 'This column is impacted by filters',
  orderIconTooltip: 'This column can be sorted by clicking this icon',
  completedBy: 'Completed By',
  allLocations: 'All Locations',
  allBlocks: 'All Blocks',
  samplePlanMap: 'Sample Plan Map',
  projectInstructions: 'Project instructions',
  samplePlanProjectInstructionsPlaceholder:
    'Write field instructions here for collecting sampling data… e.g. Take at least 2 cuttings per vine.',
  notesProjectInstructionsPlaceholder:
    'Write field instructions here for the project e.g. Take at least 2 cuttings per vine.',
  marginOfError: 'Margin of Error',
  divergence: 'Divergence',
  sample: 'Sample',
  moreInfo: 'More Info',
  unableToPrintMapTitle: 'Unable to print map',
  unableToPrintMapMessage:
    'Could not print the map. Ensure pop-ups are enabled.',
  createProjectLabel: 'Create a Project',
  editProjectLabel: 'Edit Project',
  deleteProjectLabel: 'Delete Project',
  deleteProjectPromptMessage1:
    'Deleting will remove all {{projectNoteCount}} associated notes with {{projectName}}.',
  deleteProjectPromptMessage2: 'Are you sure you want to proceed?',
  deleteProjectSuccessMessage: 'Project deleted successfully',
  deleteProjectFailedMessage: 'Project failed to delete',
  deleteSelectedNotesPromptLabel: 'Delete Selected Notes',
  deleteSelectedNotesPromptMessage:
    'All selected notes will be deleted. Continue?',
  deleteNotesSuccessMessage: 'Note/s deleted successfully',
  deleteNotesFailedMessage: 'Note/s failed to delete',
  moveAllProjectNotesLabel: 'Move all notes to',
  moveAllProjectNotesPromptLabel: 'Move Project Notes',
  moveAllProjectNotesPromptMessage:
    "All notes will be moved into '{{targetProjectName}}' from '{{currentProjectName}}'. Continue?",
  moveAllProjectNotesSuccessMessage: 'Note/s moved successfully',
  moveAllProjectNotesFailedMessage: 'Note/s failed to move',
  moveNotesModalTitle: 'Move note',
  moveNotesModalDescription: 'Select where you would like to move your note/s.',
  selectNotesLabel: 'Select',
  selectAllNotesLabel: 'Select All Notes',
  makeProjectPrivateLabel: 'Make private',
  projectLabel: 'Project',
  projectNameLabel: 'Project Name',
  projectNamePlaceholder: 'Enter project name',
  projectInstructionsLabel: 'Project Instructions',
  projectInstructionsPlaceholder: 'Enter project instructions',
  projectCreateNewLabel: 'Or Create a New Project',
  samplePlanMissingDatasourcesWarning:
    'Warning: Some of your selected blocks do not have consistent data availability. Return to block selection and filter by date.',
  samplePlanAllFlightDates: 'All Flight Dates',
  samplePlanDuplicate: 'Duplicate Plan',
  samplePlanDuplicateConfirmation: 'Please enter a name for the duplicate plan',
  samplePlanRename: 'Rename Plan',
  samplePlanDelete: 'Delete Plan',
  samplePlanDeleteConfirmation:
    'Are you sure you want to delete this sampling plan?',
  sampleTemplate: 'Sample Template',
  samplePlanVinesPerRow: 'Vines per Row',
  samplePlanEnterInfoTitle: `Enter Sampling Information`,
  projectsToExport: 'Select Projects to Export',
  tools: 'Tools',
  rateMap: 'Create Rate Map',
  rateMapDescription:
    'Create VRA (Variable Rate Application) plans based on your data',
  samplePlanDescription: 'Create and manage sampling plans based on your data',
  rateMapNameColumnLabel: 'Name',
  rateMapDateColumnLabel: 'Creation Date',
  rateMapBlockColumnLabel: 'Blocks',
  rateMapAmendmentTypeColumnLabel: 'Amend. Type',
  rateMapEditColumnButtonLabel: 'View Map',
  newRateMap: 'New Rate Map',
  rateMapEmptyLabel: 'It looks like you haven’t created a Rate Map yet',
  rateMapCreateCustomZones: 'Create or Edit Custom Zones',
  rateMapDelete: 'Delete Rate Map',
  rateMapDeleteConfirmation: 'Are you sure you want to delete this rate map?',
  rateMapDuplicate: 'Duplicate Rate Map',
  rateMapDuplicateConfirmation:
    'Please enter a name for the duplicate rate map.',
  rateMapDownloadLabel: 'Download Rate Map',
  rateMapDownloadBlockSelectionLabel: 'Block Selection',
  rateMapDownloadFormatLabel: 'Download Format',
  noAvailableDates: 'No Available Dates',
  rateMapMapColor: 'Map Color',
  rateMapRateOfApplication: 'Rate of Application',
  rateMapTotalAmountPerZone: 'Total Amount per Zone',
  rateMapVigorZoneTypeTitle: 'Select Vigor Zone Type',
  rateMapVigorZoneTypeDescription:
    'Select a base vigor zone to assign rate values',
  rateMapSetUpAmendmentTitle: 'Set up Amendment',
  rateMapSetUpAmendmentDescription:
    'Configure the amendment details for each individual zone',
  rateMapFinalOutputTitle: 'Final Output',
  rateMapFinalOutputDescription:
    'The total application amount and cost based on your selected settings',
  rateMapSupplimentalDataLayersTitle: 'Supplimental Data Layers',
  rateMapSupplimentalDataLayersDescription: '',
  rateMapAmendmentTypeTitle: 'Amendment',
  rateMapUnitTypeTitle: 'Units',
  rateMapUnitCostTitle: 'Unit Cost',
  rateMapTotalApplicationAmountTitle: 'Total Application Amount',
  rateMapCostTitle: 'Cost',
  rateMapAddAmendmentModalTitle: 'Add Amendment',
  rateMapDeleteAmendmentModalTitle: 'Delete Amendment',
  rateMapDeleteAmendmentModalDescription: 'Are you sure you want to delete ',
  rateMapAddAmendmentNameLabel: 'Amendment Name',
  rateMapSaveModalTitle: 'Save Rate Map',
  rateMapAbsoluteZones: 'Absolute zones',
  rateMapRelativeZones: 'Relative zones',
  rateMapRate: 'Rate',
  rateMapAmount: 'Amount',
  rateMapAmendmentDuplicateError:
    'An amendment with this name already exists in your organization.',
  rateMapDeleteAmendmentModalAffectedMaps: 'This will affect {{count}}',
  rateMapDeleteAmendmentModalAffectedMapsSingular:
    'other rate map in your organization.',
  rateMapDeleteAmendmentModalAffectedMapsPlural:
    'other rate maps in your organization.',
  rateMapInvalidGeometryWarning: 'You have overlapping edges in your geometry.',
  rateMapMoreOptions: 'More Options',
  rateMapRectangle: 'Rectangle',
  rateMapPolygon: 'Polygon',
  rateMapSnapping: 'Snapping',
  rateMapLimitToBounds: 'Limit To Bounds',
  rateMapCreateNewZone: 'Add New Zone',
  rateMapConfirmAllZones: 'Confirm All Zones',
  rateMapPropertyName: 'Property Name',
  rateMapSupplementalDataLayersTitle: 'Supplemental Data Layers',
  rateMapSupplementalDataLayersDescription: 'View additional data layers',
  rateMapNoSupplementalDataLayers: 'No layers available for the selected date.',
  rateMapLimitToBoundsTooltip:
    'New zones will truncate at the white parcel boundaries',
  rateMapSnappingTooltip:
    'Polygons will snap to the edges of adjacent polygons',
  rateMapPolygonTooltip: 'Draw a custom polygon zone',
  rateMapRectangleTooltip: 'Draw a rectangular zone',
  filterListByMapView: 'Filter List by Map View',
  colourProfileType: 'Type',
}
