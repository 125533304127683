import { Box, ListItemText, Menu, MenuItem, Typography } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { createStyles, withStyles, WithStyles } from '@mui/styles'
import * as React from 'react'
import { PureComponent } from 'react'
import ColorProfilePreview from '../../admin/mapdata/color-profiles/ColorProfilePreview'
import { selectSelectedLegendProductId } from '../../data/selectSelectedLegendProductId'
import { MapColorProfileData } from '../../data/types'
import i18n, { keys } from '../../i18n'
import LayerDrawerSubheader from '../../postgis/LayerDrawer/LayerDrawerSubheader'
import {
  selectConvertedProductColorProfilesByVisualizations,
  selectConvertedSelectedColorProfiles,
} from '../../ProductStats/selectors/stats'

import { connect } from '../../redux/connect'
import { AppDispatchProps, RootStore } from '../../redux/types'
import { classnames } from '../../util/classnames'
import { updateProductSettings } from '../store/redux'
import { selectActiveProductSettings } from '../store/selectors/selectActiveProductSettings'

interface State {
  anchorEl?: HTMLElement
}

class ColorSchemeSelect extends PureComponent<
  ReduxProps & WithStyles<typeof styles> & AppDispatchProps,
  State
> {
  state: State = {}

  handleClose = () => {
    this.setState({ anchorEl: undefined })
  }

  handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  render() {
    const { colorProfiles, selectedColorProfiles } = this.props

    const hasNoProfiles = !colorProfiles || colorProfiles.length === 0
    return (
      <Box sx={{ height: 90, marginBottom: 2 }}>
        {hasNoProfiles ? null : (
          <>
            <LayerDrawerSubheader
              style={{
                cursor: 'pointer',
                textAlign: 'left',
                paddingLeft: 0,
                paddingRight: 0,
                whiteSpace: 'nowrap',
                textTransform: 'none',
              }}
              onClick={this.handleOpen}
            >
              {i18n.t(keys.colorScheme.chooseColorScheme)}
              <ColorProfilePreview {...selectedColorProfiles} />
            </LayerDrawerSubheader>
            <Menu
              open={!!this.state.anchorEl}
              anchorEl={this.state.anchorEl}
              onClose={this.handleClose}
            >
              {this.renderSelectList(colorProfiles)}
            </Menu>
          </>
        )}
      </Box>
    )
  }

  renderSelectList = (productColors: this['props']['colorProfiles']) => {
    const { classes, selectedColorProfiles } = this.props

    return productColors!
      .sort((a, b) => {
        if (a.display < b.display) {
          return 1
        }
        if (a.display > b.display) {
          return -1
        }

        return 0
      })
      .map((colorProfile) => {
        const className = classnames('ColorSchemeList__colorSchemeListItem', [
          classes.selected,
          selectedColorProfiles?.id === colorProfile.id,
        ])

        return (
          <MenuItem
            key={colorProfile.id!}
            divider
            style={{ height: 'inherit' }}
            onClick={() => this.handleItemClick(colorProfile)}
            className={className}
          >
            <ListItemText
              disableTypography
              primary={
                <div>
                  <Typography variant="subtitle1">
                    {colorProfile.name}
                  </Typography>
                  <Typography variant="caption">
                    {colorProfile.description}
                  </Typography>
                </div>
              }
              secondary={<ColorProfilePreview {...colorProfile} />}
            />
          </MenuItem>
        )
      })
  }

  handleItemClick = (colorProfile: MapColorProfileData) => {
    const { settings, dispatch, productId } = this.props
    this.handleClose()

    if (!settings) {
      return
    }

    dispatch(
      updateProductSettings({
        productId,
        settings: {
          colorIdByVisualization: {
            [settings.visualization!]: colorProfile.id,
          },
        },
      })
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    colorSchemeDescription: {
      marginTop: theme.spacing(1 / 2),
      marginBottom: theme.spacing(1 / 2),
    },
    selected: {
      backgroundColor: theme.palette.divider,
    },
  })

const mapState = (state: RootStore) => {
  const settings = selectActiveProductSettings(state)
  const productId = selectSelectedLegendProductId(state)

  const colorProfiles =
    selectConvertedProductColorProfilesByVisualizations(state)[productId!]
  const selectedColorProfiles =
    selectConvertedSelectedColorProfiles(state)[productId!]

  return {
    settings,
    productId,
    colorProfiles: colorProfiles?.[settings.visualization!],
    selectedColorProfiles: selectedColorProfiles?.[settings.visualization!],
  }
}

type ReduxProps = ReturnType<typeof mapState>

export default connect<ReduxProps, {}, AppDispatchProps>(mapState)(
  withStyles(styles)(ColorSchemeSelect)
)
